import React from 'react';
import PropTypes from 'prop-types';
import FlagJapanSvg from './svgs/flag_japan.svg?react';

export const SearchProduct = (props) => {
  const {
    categories = []
  } = props;

  const [ selectedCategory, setSelectedCategory ] = React.useState("All Category");

  return(
    <div className="bg-[#626262] rounded-lg mb-2 p-3 flex items-center">
      <div className="mr-2">
        <div className="flex items-center text-white whitespace-nowrap text-[0.8rem]">
          <span className="material-symbols-outlined">
            flight
          </span>
          Ship from
        </div>
        <div className="flex items-center text-white whitespace-nowrap font-bold">
          <FlagJapanSvg />
          Japan
        </div>
      </div>
      <div className="join w-full rounded-full relative z-10">
        <div className="group join-item w-64 bg-base-200 whitespace-nowrap px-4 py-2 flex justify-start items-center cursor-pointer">
          {selectedCategory}
          <span className="material-symbols-outlined ml-auto text-base">
            keyboard_arrow_down
          </span>
          <div className="h-0 overflow-hidden absolute top-full left-0 bg-white w-9/12 shadow-2xl rounded-md opacity-0 translate-y-6 transition group-hover:opacity-100 group-hover:h-auto group-hover:translate-y-0">
            <div className="category grid grid-cols-4 gap-2 p-3">
              <CategoryBoxItem
                onClick={setSelectedCategory.bind(null, "All Category")}
                active={selectedCategory === 'All Category'}
              >
                All Category
              </CategoryBoxItem>
              {categories.map((category, i) => (
                <CategoryBoxItem
                  key={i}
                  onClick={setSelectedCategory.bind(null, category.name)}
                  active={selectedCategory === category.name}
                >
                  {category.name}
                </CategoryBoxItem>
              ))}
            </div>
          </div>
        </div>
        <input className="input join-item w-full focus:outline-none focus:border-0" placeholder="Type product name" />
        <div className="indicator">
          <button className="btn join-item">
            <span className="material-symbols-outlined text-[#999999]">
              search
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}
SearchProduct.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object)
}

const CategoryBoxItem = (props) => {
  const {
    onClick = () => {},
    active = false,
    ...rest
  } = props;

  let className = "category-item text-[#626262] p-3 rounded-lg cursor-pointer hover:bg-[#ececec]";
  if (active) {
    className += " bg-primary hover:bg-primary"
  }

  return (
    <div
      className={className}
      onClick={onClick}
      {...rest}
    />
  )
}
CategoryBoxItem.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func
}
