import { Header } from "../Header";
import { Footer } from '../Footer';
import { SearchProduct } from "../SearchProduct";
import { Container } from "./Container";
import { BottomNavigation } from "../BottomNavigation";

const categories = [
  {
    name: 'Tire and wheel set',
  },
  {
    name: 'Tire',
  },
  {
    name: 'Wheel',
  },
  {
    name: 'Chain, anti-slip',
  },
  {
    name: 'Spacer',
  },
  {
    name: 'Wheel cap',
  },
  {
    name: 'Wheel nut',
  }
]
export const CommonLayout = props => {
  const {
    className = "",
    ...rest
  } = props;
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <section className="bg-base-200 py-4 hidden">
        <Container>
          <SearchProduct categories={categories} />
        </Container>
      </section>
      <div className={"body flex-1 bg-base-200 " + className} {...rest} />
      <section className="bg-base-300 py-4 lg:block hidden">
        <Container>
          <Footer />
        </Container>
      </section>
      <BottomNavigation />
    </div>
  )
}
