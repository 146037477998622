import React from "react";
import { CommonLayout } from "../components/layouts/CommonLayout"
import { Container } from "../components/layouts/Container"
import { PageTitle } from "../components/styled/PageTitle"
import { InputPassword } from "../components/InputPassword";
import { Box } from "../components/layouts/Box"
import { useTranslation, Trans } from "react-i18next"
import axios from "../lib/axios";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addErrorToast } from "../stores/features/toast/toastSlice";

export const ResetPasswordPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { token = null } = useParams();
  const [ newPassword, setNewPassword ] = React.useState('');
  const [ newPassword2, setNewPassword2 ] = React.useState('');
  const [ errorMessages, setErrorMessages ] = React.useState([]);
  const [ disabledSubmit, setSubmitDisable ] = React.useState(true);
  const [ done, setDone ] = React.useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/v1/resetPassword', {
        token,
        newPassword
      });
      setErrorMessages([])
      setNewPassword('');
      setNewPassword2('');
      setDone(true);
    } catch(error) {
      error.response.data.errorMessages.forEach(
        err => dispatch(addErrorToast(err))
      )
    }
  }

  React.useEffect(() => {
    if (newPassword === '' || newPassword2 === '') {
      return;
    }

    if (newPassword !== newPassword2) {
      setErrorMessages([
        'new password is not matching'
      ])
      setSubmitDisable(true);
      return;
    }
    setSubmitDisable(false);
  }, [
    newPassword,
    newPassword2
  ])

  return (
    <CommonLayout>
      <section className="bg-base-200">
        <Container>
          <PageTitle>
            {t('page.reset-password')}
          </PageTitle>
          <Box className="lg:p-12 p-4">
            <div className="w-full max-w-sm mx-auto">
              <form onSubmit={onSubmit}>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text">{t('common.password')}</span>
                  </div>
                  <InputPassword
                    value={newPassword}
                    onChange={e => setNewPassword(e.target.value)}
                    autoComplete="new-password"
                  />
                </label>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text">{t('common.password2')}</span>
                  </div>
                  <InputPassword
                    value={newPassword2}
                    onChange={e => setNewPassword2(e.target.value)}
                    autoComplete="new-password"
                  />
                </label>
                <button className="btn btn-primary w-full my-4" type="submit" disabled={disabledSubmit}>
                  <span className="material-symbols-outlined">
                    password
                  </span>
                  {t('common.reset-password')}
                </button>
                {errorMessages.map((err, i) => (
                  <div key={i} className="text-error">{err}</div>
                ))}
                {
                  done &&
                    <div>
                      <Trans
                        i18nKey="resetPassword.after-reset-password"
                        components={{
                          SignInLink: <NavLink to="/signin" className="link link-primary">{t('common.signIn')}</NavLink>
                        }}
                       />
                    </div>
                }
              </form>
            </div>
          </Box>
        </Container>
      </section>
    </CommonLayout>
  )
}
