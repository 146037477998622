import React from "react"
import { useNavigate } from "react-router-dom"
import { CommonLayout } from "../components/layouts/CommonLayout"
import { Container } from "../components/layouts/Container"
import { Box } from "../components/layouts/Box"
import { useCreateUserMutation } from "../stores/services/user";
import { useTranslation } from 'react-i18next';
import { PageTitle } from "../components/styled/PageTitle"
import { InputPassword } from "../components/InputPassword"
import { InputUserName } from "../components/InputUserName"
import { InputName } from "../components/InputName"
import { useDispatch } from "react-redux";
import { addErrorToast } from "../stores/features/toast/toastSlice";

export const SignupPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ userName, setUserName ] = React.useState('');
  const [ password, setPassword ] = React.useState('');
  const [ password2, setPassword2 ] = React.useState('');
  const [ givenName, setGivenName ] = React.useState('');
  const [ familyName, setFamilyName ] = React.useState('');
  const [ errorMessages, setErrorMessages ] = React.useState([]);
  const [ submitDisable, setSubmitDisable ] = React.useState(true);

  const [ createUser ] = useCreateUserMutation();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setUserName('guest@gmail.com');
      setPassword('PAssW0rd!');
      setPassword2('PAssW0rd!');
      setGivenName('guest');
      setFamilyName('user');
    }
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await createUser({
        email: userName,
        password,
        given_name: givenName,
        family_name: familyName
      });
      if (res.error === undefined) {
        navigate('/signin');
      } else {
        if (res.error.status === 409) {
          dispatch(addErrorToast('The Account Already Exists'))
        } else if(res.error.status === 400) {
          res.error.data.errorMessages.forEach(
            err => dispatch(addErrorToast(err))
          )
        }
      }
    } catch(error) {
      error.response.data.errorMessages.forEach(
        err => dispatch(addErrorToast(err))
      )
    }
  }

  React.useEffect(() => {
    if (userName === '' || password === '' || password2 === '' || givenName === '' || familyName === '') {
      setSubmitDisable(true);
      return;
    }
    if (password !== password2) {
      setErrorMessages([
        'password is not matching'
      ]);
      setSubmitDisable(true);
      return;
    }
    setErrorMessages([]);
    setSubmitDisable(false);
  }, [
    userName,
    password,
    password2,
    givenName,
    familyName
  ])

  return (
    <CommonLayout>
      <section className="bg-base-200">
        <Container>
          <PageTitle>
            {t('common.signUp')}
          </PageTitle>
          <Box className="lg:p-12 p-4">
            <div className="w-full max-w-sm mx-auto">
              <form onSubmit={onSubmit}>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text">{t('common.email-address')}</span>
                  </div>
                  <InputUserName
                    value={userName}
                    onChange={e => setUserName(e.target.value)}
                    autoComplete="username"
                  />
                  <div className="label">
                    <span className="label-text">{t('signUp.your-email-address-will-be')}</span>
                  </div>
                </label>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text">{t('common.password')}</span>
                  </div>
                  <InputPassword
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    autoComplete="new-password"
                  />
                  <div className="label">
                    <span className="label-text">Use 8 or more characters with a mix of upper, lower, numbers and symbols</span>
                  </div>
                </label>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text">{t('common.password2')}</span>
                  </div>
                  <InputPassword
                    value={password2}
                    onChange={e => setPassword2(e.target.value)}
                    autoComplete="new-password"
                  />
                  <div className="label">
                    <span className="label-text">Type again same password</span>
                  </div>
                </label>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text">{t('common.given-name')}</span>
                  </div>
                  <InputName
                    value={givenName}
                    onChange={e => setGivenName(e.target.value)}
                    autoComplete="given-name"
                  />
                </label>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text">{t('common.last-name')}</span>
                  </div>
                  <InputName
                    value={familyName}
                    onChange={e => setFamilyName(e.target.value)}
                    autoComplete="family-name"
                  />
                </label>

                <button className="btn btn-primary w-full my-4" type="submit" disabled={submitDisable}>
                  <span className="material-symbols-outlined">
                    person_add
                  </span>
                {t('signUp.create-account')}
                </button>
                {errorMessages.map((err, i) => (
                  <div key={i} className="text-error">{err}</div>
                ))}
              </form>
            </div>
          </Box>
        </Container>
      </section>
    </CommonLayout>
  )
}
