import React from "react";
import PropTypes from 'prop-types';
import { ChooseListDrawer } from "./ChooseListDrawer";
import { useGetCategoryGroupsQuery } from "../stores/services/categoryGroup";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import { convertBase64ToBlob } from '../lib/utils';

const CategoryGroupChooseListDrawerComponent = (props) => {

  const {
    lang,
    value,
    onSelect = () => {},
    ...rest
  } = props;
  const { t } = useTranslation();

  const {
    data = [],
    error,
    isLoading
  } = useGetCategoryGroupsQuery();

  const [ _data, _setData ] = React.useState([]);

  React.useEffect(() => {
    if (isLoading) return;

    _setData([
      ...data.map(
        d => ({
          ...d,
          imageUrl: URL.createObjectURL(
            convertBase64ToBlob(d.image)
          )
        })
      )
    ])
  }, [
    data,
    isLoading
  ])

  return (
    <ChooseListDrawer
      label={t('chooseListDrawer.choose-website')}
      value={value}
      options={_data}
      onSelect={onSelect}
      labelProp={`name_${lang}`}
      valueProp="id"
      {...rest}
    />
  )
}
CategoryGroupChooseListDrawerComponent.propTypes = {
  lang: PropTypes.string,
  value: PropTypes.any,
  onSelect: PropTypes.func
}
export const CategoryGroupChooseListDrawer = connect((state) => ({ ...state.i18n }))(CategoryGroupChooseListDrawerComponent);
// vim:sw=2:ai
