import React from "react"
import { CommonLayout } from "../components/layouts/CommonLayout"
import { Container } from "../components/layouts/Container"
import { useTranslation, Trans } from 'react-i18next';
import { NavLink } from "react-router-dom";

export const QAPage = () => {
  const { t, i18n } = useTranslation();

  const getChildren = groupId => {
    let arr = [];
    let i = 1;
    while (true) {
      if (i18n.exists('qa.group' + groupId + '.q' + i + '.title')) {
        arr.push(
          <QAAccordion
            title={`qa.group${groupId}.q${i}.title`}
            body={`qa.group${groupId}.q${i}.body`}
          />
        )
      } else {
        break;
      }
      i++;
    }
    return arr;
  }

  return (
    <CommonLayout className="py-4 h-full">
      {
        Array
          .from(Array(6).keys())
          .map(i => (
            <section key={i} className="my-4">
              <Container>
                <h2 className="text-lg font-bold p-4 text-primary">{t(`qa.group${i+1}.title`)}</h2>
                <div className="join join-vertical w-full">
                  {getChildren(i+1)}
                </div>
              </Container>
            </section>
          ))
      }
    </CommonLayout>
  )
}

const QAAccordion = (props) => {
  const {
    title = "",
    body = ""
  } = props;
  const { t } = useTranslation();

  const [ open, setOpen ] = React.useState(false);

  return (
    <label
      className={"collapse collapse-arrow join-item border-base-300 border " + (open ? 'collapse-open': '')}
      onClick={() => setOpen(!open)}
    >
      <div className="collapse-title text-lg font-medium">
        <Trans i18nKey={title} />
      </div>
      <div className="collapse-content">
        <p>
          <Trans
            i18nKey={body}
            components={{
              TermOfUseLink: <NavLink to="/terms-of-use" className="link link-primary">{t('common.terms-of-use')}</NavLink>,
              SignUpLink: <NavLink to="/signup" className="link link-primary">{t('common.here')}</NavLink>
            }}
          />
        </p>
      </div>
    </label>
  )
}
