import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../fetchBaseQuery';

export const imageApi = createApi({
    reducerPath: 'imageApi',
    baseQuery,
    tagTypes: [
        'Image'
    ],
    endpoints: (builder) => ({
        getImages: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/images';
                } else {
                    return '/images?' + new URLSearchParams(queries);
                }
            },
            providesTags: (result, error, arg) =>
            result
            ? [...result.map(({ id }) => ({ type: 'Image', id })), 'Image']
            : ['Image'],
        }),
        countImages: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/images/counts';
                } else {
                    return '/images/counts?' + new URLSearchParams(queries);
                }
            }
        }),
        getImage: builder.query({
            query: (id) => `/image/${id}`,
            providesTags: (result, error, arg) => [{type: 'Image', id: result.id}]
        }),
        createImage: builder.mutation({
            query: (body) => ({
                url: `/image`,
                method: 'POST',
                body,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: ['Image']
        }),
        updateImage: builder.mutation({
            query: ({ id, ...patch }) => ({
                url: `/image/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'Image', id: arg.id}]
        }),
        deleteImage: builder.mutation({
            query: (id) => ({
                url: `/image/${id}`,
                method: 'DELETE'
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'Image', id: arg.id}]
        }),
    }),
})

export const {
    useGetImagesQuery,
    useCountImagesQuery,
    useGetImageQuery,
    useCreateImageMutation,
    useUpdateImageMutation,
    useDeleteImageMutation,
} = imageApi
