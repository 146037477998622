import PropTypes from 'prop-types';

export const Cube = (props) => {
  const {
    iconName,
    label
  } = props;
  return (
    <div className="flex flex-col justify-center p-2 rounded-lg border border-base-300 aspect-square w-full">
      <span className="material-symbols-outlined text-center text-[3rem]">
        {iconName}
      </span>
      <div className="text-center text-sm mt-2">
        {label}
      </div>
    </div>
  )
}
Cube.propTypes = {
  iconName: PropTypes.string,
  label: PropTypes.string
}
