import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { ChooseListDrawer } from "./ChooseListDrawer";
import { useGetCarMakesQuery } from "../stores/services/carMake";
import { useTranslation } from 'react-i18next';

export const MakeChooseListDrawerComponent = (props) => {

  const {
    lang,
    value,
    onSelect = () => {},
    ...rest
  } = props;
  const { t } = useTranslation();

  const {
    data = [],
    error,
    isLoading
  } = useGetCarMakesQuery();

  return (
    <ChooseListDrawer
      label={t('chooseListDrawer.choose-make')}
      value={value}
      options={data}
      onSelect={onSelect}
      labelProp={`name_${lang}`}
      valueProp="id"
      {...rest}
    />
  )
}
MakeChooseListDrawerComponent.propTypes = {
  lang: PropTypes.string,
  value: PropTypes.any,
  onSelect: PropTypes.func
}
export const MakeChooseListDrawer = connect((state) => ({ ...state.i18n }))(MakeChooseListDrawerComponent);
// vim:sw=2:ai
