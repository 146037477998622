import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../fetchBaseQuery';

export const carModelApi = createApi({
    reducerPath: 'carModelApi',
    baseQuery,
    tagTypes: [
        'CarModel'
    ],
    endpoints: (builder) => ({
        getCarModels: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/carModels';
                } else {
                    return '/carModels?' + new URLSearchParams(queries);
                }
            },
            providesTags: (result, error, arg) =>
            result
            ? [...result.map(({ id }) => ({ type: 'CarModel', id })), 'CarModel']
            : ['CarModel'],
        }),
        countCarModels: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/carModels/counts';
                } else {
                    return '/carModels/counts?' + new URLSearchParams(queries);
                }
            }
        }),
        getCarModel: builder.query({
            query: (id) => `/carModel/${id}`,
            providesTags: (result, error, arg) => [{type: 'CarModel', id: result.id}]
        }),
        createCarModel: builder.mutation({
            query: (body) => ({
                url: `/carModel`,
                method: 'POST',
                body,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: ['CarModel']
        }),
        updateCarModel: builder.mutation({
            query: ({ id, ...patch }) => ({
                url: `/carModel/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'CarModel', id: arg.id}]
        }),
        deleteCarModel: builder.mutation({
            query: (id) => ({
                url: `/carModel/${id}`,
                method: 'DELETE'
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'CarModel', id: arg.id}]
        }),
    }),
})

export const {
    useGetCarModelsQuery,
    useCountCarModelsQuery,
    useGetCarModelQuery,
    useCreateCarModelMutation,
    useUpdateCarModelMutation,
    useDeleteCarModelMutation,
} = carModelApi
