import React from "react";
import PropTypes from "prop-types";

export const InputTireSize = (props) => {
  const {
    value = "",
    onChange = () => {},
    className = "",
  } = props;

  const [ first, setFirst ] = React.useState();
  const [ second, setSecond ] = React.useState();
  const [ third, setThird ] = React.useState();

  React.useEffect(() => {
    const [ first = '', rest = '' ] = value.split('/');
    const [ second = '', third = '' ] = rest.split('R');

    setFirst(first);
    setSecond(second)
    setThird(third);
  }, [ value ])

  const constructValue = (a, b, c) => {
    if (a === '' || b === '' || c === '') return '';
    return `${a}/${b}R${c}`
  }

  return (
    <label className={"input input-bordered focus-within:outline-none flex items-center gap-2 " + className}>
      <input
        type="number"
        className="w-full"
        placeholder="215"
        value={first}
        onChange={e => { setFirst(e.target.value); onChange(constructValue(e.target.value, second, third)) }}
      />
      <span>/</span>
      <input
        type="number"
        className="w-full"
        placeholder="45"
        value={second}
        onChange={e => { setSecond(e.target.value); onChange(constructValue(first, e.target.value, third)) }}
      />
      <span>R</span>
      <input
        type="number"
        className="w-full"
        placeholder="18"
        value={third}
        onChange={e => { setThird(e.target.value); onChange(constructValue(first, second, e.target.value)) }}
      />
    </label>
  )
}

InputTireSize.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string
}
