export const convertBase64ToBlob = b64Data => {
  const bin = atob(b64Data);
  const buffer = new Uint8Array(bin.length);
  for (let i=0; i<bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }
  return new Blob([buffer.buffer])
}
export const convertBufferToBase64 = buffer => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode( bytes[ i ] );
  }
  return window.btoa( binary );
}
// vim:sw=2:ai
