import axios from "axios";
import store from '../stores';
import { signOut } from "../stores/features/user/userSlice";

const axiosCustomInstance = axios.create();
axiosCustomInstance.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    Authorization: window.localStorage.getItem("sessionId"),
  };
  return config;
});
axiosCustomInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401 && !error.config.skipErrorHandling) {
      window.localStorage.removeItem("sessionId");
      store.dispatch(signOut());
      window.location.href = "/signin";
      return;
    }
    return Promise.reject(error);
  },
);

export default axiosCustomInstance;
