import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { ChooseListDrawer } from "./ChooseListDrawer";
import { useGetCarModelsQuery } from "../stores/services/carModel";
import { useTranslation } from 'react-i18next';

export const ModelChooseListDrawerComponent = (props) => {

  const {
    lang,
    carMakeId = -1,
    value,
    onSelect = () => {},
    ...rest
  } = props;
  const { t } = useTranslation();

  const {
    data = [],
    error,
    isLoading
  } = useGetCarModelsQuery({
    carMakeId
  });

  return (
    <ChooseListDrawer
      label={t('chooseListDrawer.choose-model')}
      value={value}
      options={data}
      onSelect={onSelect}
      labelProp={`name_${lang}`}
      valueProp="id"
      {...rest}
    />
  )
}
ModelChooseListDrawerComponent.propTypes = {
  carMakeId: PropTypes.number,
  lang: PropTypes.string,
  value: PropTypes.any,
  onSelect: PropTypes.func
}
export const ModelChooseListDrawer = connect((state) => ({ ...state.i18n }))(ModelChooseListDrawerComponent);
