import { configureStore } from "@reduxjs/toolkit";
import toastReducer from "./features/toast/toastSlice";
import userReducer from "./features/user/userSlice";
import i18nReducer from "./features/i18n/i18nSlice";
import * as services from "./services";

export default configureStore({
  reducer: {
    toast: toastReducer,
    user: userReducer,
    i18n: i18nReducer,
    ...Object.fromEntries(
      Object.entries(services).map(([, v]) => [v.reducerPath, v.reducer]),
    ),
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      ...Object.entries(services).map(([, v]) => v.middleware),
    ),
});
