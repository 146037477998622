import React from "react"
import PropTypes from 'prop-types';

export const InputPassword = (props) => {
  const {
    value = "",
    onChange = () => {},
    autoComplete = "",
    ...rest
  } = props;

  const [ passwordVisible, setPasswordVisible ] = React.useState(false);
  return (
    <label className="input input-bordered flex items-center gap-2 focus-within:outline-none">
      <span className="material-symbols-outlined text-base text-primary-content/50">
        key
      </span>
      <input
        type={passwordVisible ? 'text' : 'password'}
        className="grow"
        minLength="8"
        maxLength="25"
        value={value}
        onChange={onChange}
        autoComplete={autoComplete}
        pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,25}$"
        passwordrules="required: upper; required: lower; required: digit; allowed: [-().&@?'#,/&quot;+]; max-consecutive: 2"
        required
        {...rest}
      />
      {
        !passwordVisible &&
          <span className="material-symbols-outlined text-base" onClick={setPasswordVisible.bind(null, true)}>
            visibility
          </span>
      }
      {
        passwordVisible &&
          <span className="material-symbols-outlined text-base" onClick={setPasswordVisible.bind(null, false)}>
            visibility_off
          </span>
      }
    </label>
  )
}
InputPassword.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  autoComplete: PropTypes.string
}
