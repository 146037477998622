import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../fetchBaseQuery';

export const recommendedCategoryApi = createApi({
    reducerPath: 'recommendedCategoryApi',
    baseQuery,
    tagTypes: [
        'RecommendedCategory'
    ],
    endpoints: (builder) => ({
        getRecommendedCategorys: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/recommendedCategorys';
                } else {
                    return '/recommendedCategorys?' + new URLSearchParams(queries);
                }
            },
            providesTags: (result, error, arg) =>
            result
            ? [...result.map(({ id }) => ({ type: 'RecommendedCategory', id })), 'RecommendedCategory']
            : ['RecommendedCategory'],
        }),
        countRecommendedCategorys: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/recommendedCategorys/counts';
                } else {
                    return '/recommendedCategorys/counts?' + new URLSearchParams(queries);
                }
            }
        }),
        getRecommendedCategory: builder.query({
            query: (id) => `/recommendedCategory/${id}`,
            providesTags: (result, error, arg) => [{type: 'RecommendedCategory', id: result.id}]
        }),
        createRecommendedCategory: builder.mutation({
            query: (body) => ({
                url: `/recommendedCategory`,
                method: 'POST',
                body,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: ['RecommendedCategory']
        }),
        updateRecommendedCategory: builder.mutation({
            query: ({ id, ...patch }) => ({
                url: `/recommendedCategory/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'RecommendedCategory', id: arg.id}]
        }),
        deleteRecommendedCategory: builder.mutation({
            query: (id) => ({
                url: `/recommendedCategory/${id}`,
                method: 'DELETE'
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'RecommendedCategory', id: arg.id}]
        }),
    }),
})

export const {
    useGetRecommendedCategorysQuery,
    useCountRecommendedCategorysQuery,
    useGetRecommendedCategoryQuery,
    useCreateRecommendedCategoryMutation,
    useUpdateRecommendedCategoryMutation,
    useDeleteRecommendedCategoryMutation,
} = recommendedCategoryApi
