import React from "react"
import { CommonLayout } from "../components/layouts/CommonLayout"
import { Container } from "../components/layouts/Container"
import { useTranslation } from 'react-i18next';
import { Flow } from "../components/Flow";

export const FirstTimeUserPage = () => {
  const { t } = useTranslation();

  return (
    <CommonLayout className="py-4">
      <section className="my-4">
        <Container>
          <h2 className="text-3xl text-center font-bold p-4 text-primary">{t('firstTimeUser.what-is-tsuno')}</h2>
          <p className="p-4 text-lg">
            {t('firstTimeUser.what-is-tsuno.content')}
          </p>
        </Container>
      </section>
      <section className="my-4">
        <Container>
          <h2 className="text-3xl text-center font-bold p-4 text-primary">{t('firstTimeUser.how-it-works')}</h2>
          <p className="p-4 text-lg">
            {t('firstTimeUser.how-it-works.content')}
          </p>
        </Container>
      </section>
      <section className="my-4">
        <Container>
          <h2 className="text-3xl text-center font-bold p-4 text-primary">{t('firstTimeUser.features-and-benefits')}</h2>
          <h3 className="text-xl font-bold p-4">{t('firstTimeUser.features-and-benefits.1.title')}</h3>
          <p className="p-4 text-lg">
            {t('firstTimeUser.features-and-benefits.1.body')}
          </p>
          <h3 className="text-xl font-bold p-4">{t('firstTimeUser.features-and-benefits.2.title')}</h3>
          <p className="p-4 text-lg">
            {t('firstTimeUser.features-and-benefits.2.body')}
          </p>
          <h3 className="text-xl font-bold p-4">{t('firstTimeUser.features-and-benefits.3.title')}</h3>
          <p className="p-4 text-lg">
            {t('firstTimeUser.features-and-benefits.3.body')}
          </p>
          <h3 className="text-xl font-bold p-4">{t('firstTimeUser.features-and-benefits.4.title')}</h3>
          <p className="p-4 text-lg">
            {t('firstTimeUser.features-and-benefits.4.body')}
          </p>
          <h3 className="text-xl font-bold p-4">{t('firstTimeUser.features-and-benefits.5.title')}</h3>
          <p className="p-4 text-lg">
            {t('firstTimeUser.features-and-benefits.5.body')}
          </p>
        </Container>
      </section>
      <section className="my-4">
        <Container>
          <h2 className="text-3xl text-center font-bold p-4 text-primary">{t('firstTimeUser.step-flow')}</h2>
          <div className="py-4">
            <Flow />
          </div>
        </Container>
      </section>
      <section className="my-4">
        <Container>
          <h2 className="text-3xl text-center font-bold p-4 text-primary">{t('firstTimeUser.price-list')}</h2>
          <h3 className="text-xl font-bold p-4">{t('firstTimeUser.price-list.1.title')}</h3>
          <p className="p-4 text-md">
            {t('firstTimeUser.price-list.1.body')}
          </p>
          <h3 className="text-xl font-bold p-4">{t('firstTimeUser.price-list.2.title')}</h3>
          <p className="p-4 text-md">
            {t('firstTimeUser.price-list.2.body')}
          </p>
          <p className="py-2 px-4 text-md">
            {t('firstTimeUser.price-list.2.body.1')}
          </p>
          <p className="py-2 px-4 text-md">
            {t('firstTimeUser.price-list.2.body.2')}
          </p>
          <h3 className="text-xl font-bold p-4">{t('firstTimeUser.price-list.3.title')}</h3>
          <p className="p-4 text-md">
            {t('firstTimeUser.price-list.3.body')}
          </p>
          <h3 className="text-xl font-bold p-4">{t('firstTimeUser.price-list.4.title')}</h3>
          <p className="p-4 text-md">
            {t('firstTimeUser.price-list.4.body')}
          </p>
          <div className="py-4">
            <table className="border-collapse border border-slate-500 mx-auto">
              <thead>
                <tr>
                  <th className="border border-slate-600 p-1">{t('firstTimeUser.price-list.4.body.column1')}</th>
                  <th className="border border-slate-600 p-1">{t('firstTimeUser.price-list.4.body.column2')}</th>
                  <th className="border border-slate-600 p-1">{t('firstTimeUser.price-list.4.body.column3')}</th>
                  <th className="border border-slate-600 p-1">{t('firstTimeUser.price-list.4.body.column4')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-slate-600 p-1">0.01M³ ~ 1M³</td>
                  <td className="border border-slate-600 p-1">¥18,000</td>
                  <td className="border border-slate-600 p-1">¥5,000</td>
                  <td className="border border-slate-600 p-1">¥23,000</td>
                </tr>
                <tr>
                  <td className="border border-slate-600 p-1">1.01M³ ~ 2M³</td>
                  <td className="border border-slate-600 p-1">¥27,000</td>
                  <td className="border border-slate-600 p-1">¥11,000</td>
                  <td className="border border-slate-600 p-1">¥38,000</td>
                </tr>
                <tr>
                  <td className="border border-slate-600 p-1">2.01M³ ~ 3M³</td>
                  <td className="border border-slate-600 p-1">¥37,000</td>
                  <td className="border border-slate-600 p-1">¥16,000</td>
                  <td className="border border-slate-600 p-1">¥53,000</td>
                </tr>
                <tr>
                  <td className="border border-slate-600 p-1">3.01M³ ~ 4M³</td>
                  <td className="border border-slate-600 p-1">¥50,000</td>
                  <td className="border border-slate-600 p-1">¥22,000</td>
                  <td className="border border-slate-600 p-1">¥72,000</td>
                </tr>
                <tr>
                  <td className="border border-slate-600 p-1">4.01M³ ~ 5M³</td>
                  <td className="border border-slate-600 p-1">¥65,000</td>
                  <td className="border border-slate-600 p-1">¥28,000</td>
                  <td className="border border-slate-600 p-1">¥93,000</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3 className="text-xl font-bold p-4">{t('firstTimeUser.price-list.5.title')}</h3>
          <p className="p-4 text-md">
            {t('firstTimeUser.price-list.5.body')}
          </p>

        </Container>
      </section>
    </CommonLayout>
  )
}
