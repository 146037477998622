import React from 'react';
import PropTypes from 'prop-types';
import { useGetCarMakesQuery } from "../stores/services/carMake";

export const SelectCarMake = (props) => {
  const {
    onChange = () => {},
    value = "",
    ...rest
  } = props;
  const {
    data = [],
    error,
    isLoading
  } = useGetCarMakesQuery();

  const onChangeHandler = React.useCallback((e) => {
    const make = data.find(d => d.id.toString() === e.target.value);
    onChange(make, e);
  }, [ data ])

  return (
    <select className="select select-bordered w-full focus:outline-none" value={value} onChange={onChangeHandler} {...rest}>
      <option value="" selected>--</option>
      {
        data.map(d => (
          <option key={d.id} value={d.id}>{d.name_en}</option>
        ))
      }
    </select>
  )
}
SelectCarMake.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number
}
// vim:sw=2:ai
