import { fetchBaseQuery } from "@reduxjs/toolkit/query";
// import { addInfoToast, addErrorToast } from "./features/toast/toastSlice";
import { signOut } from "./features/user/userSlice";

const base = fetchBaseQuery({
  baseUrl: "/api/v1",
  prepareHeaders: (headers) => {
    const sessionId = window.localStorage.getItem("sessionId");
    if (sessionId !== null) {
      headers.set("Authorization", `${sessionId}`);
    }

    return headers;
  },
});

export const baseQuery = async (args, api, options) => {
  const result = await base(args, api, options);

  if (result?.error?.status === 401) {
    window.localStorage.removeItem("sessionId");
    api.dispatch(signOut());
    window.location.href = "/signin";
  }
  if (args?.method === undefined) {
    if (result?.error !== undefined) {
      // api.dispatch(addErrorToast("取得に失敗しました"));
    } else {
      // api.dispatch(addInfoToast("取得に成功しました"));
    }
  }
  if (["POST"].includes(args?.method?.toUpperCase())) {
    if (result?.error !== undefined) {
      // api.dispatch(addErrorToast("作成に失敗しました"));
    } else {
      // api.dispatch(addInfoToast("作成に成功しました"));
    }
  }
  if (["PUT", "PATCH"].includes(args?.method?.toUpperCase())) {
    if (result?.error !== undefined) {
      // api.dispatch(addErrorToast("更新に失敗しました"));
    } else {
      // api.dispatch(addInfoToast("更新に成功しました"));
    }
  }
  if (["DELETE"].includes(args?.method?.toUpperCase())) {
    if (result?.error !== undefined) {
      // api.dispatch(addErrorToast("削除に失敗しました"));
    } else {
      // api.dispatch(addInfoToast("削除に成功しました"));
    }
  }

  return result;
};
