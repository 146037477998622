import React from "react"
import { useDispatch } from "react-redux";
import { CommonLayout } from "../components/layouts/CommonLayout"
import { Container } from "../components/layouts/Container"
import { Box } from "../components/layouts/Box"
import axios from "../lib/axios"
import { useNavigate } from "react-router-dom"
import { signIn } from "../stores/features/user/userSlice";
import { addErrorToast } from "../stores/features/toast/toastSlice";
import { useTranslation } from 'react-i18next';
import { PageTitle } from "../components/styled/PageTitle"
import { InputPassword } from "../components/InputPassword";
import { InputUserName } from "../components/InputUserName";
import { NavLink } from "react-router-dom";

export const SigninPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ userName, setUserName ] = React.useState();
  const [ password, setPassword ] = React.useState();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setUserName('guest@gmail.com');
      setPassword('PAssW0rd!');
    }
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post('/api/v1/login', {
        userName,
        password
      }, { skipErrorHandling: true });

      dispatch(signIn(data.sessionId));
      navigate('/dashboard');
    } catch(error) {
      error.response.data.errorMessages.forEach(
        err => dispatch(addErrorToast(err))
      )
    }
  }

  return (
    <CommonLayout>
      <section className="bg-base-200">
        <Container>
          <PageTitle>
            {t('common.signIn')}
          </PageTitle>
          <Box className="lg:p-12 p-4">
            <div className="w-full max-w-sm mx-auto">
              <form onSubmit={onSubmit}>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text">{t('common.email-address')}</span>
                  </div>
                  <InputUserName
                    value={userName}
                    onChange={e => setUserName(e.target.value)}
                    autoComplete="username"
                  />
                </label>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text">{t('common.password')}</span>
                  </div>
                  <InputPassword
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    autoComplete="current-password"
                  />
                </label>
                <div className="text-end">
                  <NavLink to="/forgotPassword" className="link link-primary">
                    {t('signIn.forgot-password')}
                  </NavLink>
                </div>
                <button className="btn btn-primary w-full my-4" type="submit">
                  <span className="material-symbols-outlined">
                    login
                  </span>
                  {t('common.signIn')}
                </button>
                <div className="divider">OR</div>
                <button className="btn btn-primary btn-outline w-full my-4" type="button" onClick={navigate.bind(null, '/signup')}>
                  <span className="material-symbols-outlined">
                    person_add
                  </span>
                  {t('common.signUp')}
                </button>
              </form>
            </div>
          </Box>
        </Container>
      </section>
    </CommonLayout>
  )
}
