import PropTypes from 'prop-types';

export const Drawer = (props) => {
  const {
    open = false,
    position = "left",
    width = "80",
    onClose = () => {},
    ...rest
  } = props;

  return (
    <>
    <div
      className={[
        'fixed',
        'top-0',
        'left-0',
        'bg-black/50',
        'h-screen',
        'w-screen',
        'transition',
        'duration-300',
        open ? '' : 'hidden'
      ].join(' ')}
      onClick={onClose}
    />
    <div
      className={[
        'fixed',
        'top-0',
        `${position}-0`,
        'h-dvh',
        `w-${width}`,
        'overflow-y-auto',
        'transition',
        'duration-300',
        open ? 'tranlate-x-0' : (position === 'left' ? '-translate-x-full': 'translate-x-full')
      ].join(' ')}
      {...rest}
    />
    </>
  )
}
Drawer.propTypes = {
  open: PropTypes.bool,
  position: PropTypes.oneOf(['left', 'right']),
  width: PropTypes.string,
  onClose: PropTypes.func
}

// vim:sw=2:ai
