import React from 'react';
import PropTypes from 'prop-types';

export const SidemenuItem = (props) => {
  const {
    component = 'div',
    className = '',
    ...rest
  } = props;

  return React.createElement(component, {
    className: 'group flex items-center justify-between p-2 cursor-pointer text-primary-content/60 transition duration-300 hover:bg-primary/5 hover:translate-x-2 ' + className,
    ...rest
  })
}
SidemenuItem.propTypes = {
  component: PropTypes.string,
  className: PropTypes.string,
}
