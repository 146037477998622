import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import Baner1 from '../assets/banner_1.png';
import Baner2 from '../assets/banner_2.png';
import Baner3 from '../assets/banner_3.png';
import Baner4 from '../assets/banner_4.png';
import Baner5 from '../assets/banner_5.png';
import Baner6 from '../assets/banner_6.png';

import BanerMb1 from '../assets/banner_1_mb.png';
import BanerMb2 from '../assets/banner_2_mb.png';
import BanerMb3 from '../assets/banner_3_mb.png';
import BanerMb4 from '../assets/banner_4_mb.png';
import BanerMb5 from '../assets/banner_5_mb.png';
import BanerMb6 from '../assets/banner_6_mb.png';

import { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper/modules';

import { Container } from "../components/layouts/Container";
import { SwiperComponent } from "../components/Swiper";
import { News } from '../components/News';
import { Flow } from '../components/Flow';
import { SearchBox } from '../components/SearchBox';

import { CommonLayout } from '../components/layouts/CommonLayout';

export const IndexPage = () => {
  const { t } = useTranslation();

  return (
    <CommonLayout>
      <section className="bg-base-200 hidden lg:block lg:pb-8">
        <SwiperComponent
          images={[
            Baner1,
            Baner2,
            Baner3,
            Baner4,
            Baner5,
            Baner6,
          ]}
          modules={[Autoplay, Navigation, Pagination, Scrollbar]}
          autoplay
          navigation
          pagination
          scrollbar
        />
      </section>
      <section className="bg-base-200 lg:hidden p-4">
        <SwiperComponent
          images={[
            BanerMb1,
            BanerMb2,
            BanerMb3,
            BanerMb4,
            BanerMb5,
            BanerMb6,
          ]}
          modules={[Autoplay, Pagination, Scrollbar]}
          autoplay
          pagination
          scrollbar
        />
      </section>
      <section className="bg-base-200 p-4 hidden">
        <Container>
          <News />
        </Container>
      </section>
      <section className="bg-base-200 lg:pb-8">
        <Container>
          <SearchBox />
        </Container>
      </section>
      <section className="bg-white py-4 lg:block hidden">
        <Container>
          <div>
            <h2 className="text-xl font-bold mb-8 flex flex-wrap items-center lg:pl-0 pl-4">
              <span className="inline-block mb-4 lg:mb-0 mr-4 w-full lg:w-auto">{t('common.usage-guide')}</span>
              <span className="inline-flex items-center lg:text-primary">
                <NavLink to="/firstTimeUser" className="text-lg lg:text-sm font-normal">{t('header.first-time-users')}</NavLink>
                <span className="material-symbols-outlined rotate-90 font-normal">
                  keyboard_arrow_up
                </span>
              </span>
            </h2>
            <Flow />
          </div>
        </Container>
      </section>
    </CommonLayout>
  )
}
