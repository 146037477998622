import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import Logo from '../assets/Logo.svg';
import { Container } from './layouts/Container'
import { Clock } from './Clock';
import { useNavigate, NavLink } from "react-router-dom";
import { Link } from 'react-router-dom';
import axios from "../lib/axios";
import { useTranslation } from 'react-i18next';
import { SwitchLanguage, SwitchLanguageDrawer } from './SwitchLanguage';
import LogoBlack from '../assets/Logo_black.svg';
import { Drawer } from './layouts/Drawer';
import { getSelectedLanguageName } from "../stores/features/i18n/i18nSlice";

const HeaderComponent = (props) => {
  const navigate = useNavigate();
  const {
    isSignedIn
  } = props;
  const { t } = useTranslation();
  const [ openMenu, setOpenMenu ] = React.useState(false);

  return (
    <>
      <header className="text-white bg-[#333333] lg:block hidden">
        <Container className="flex justify-end gap-4 p-2 text-sm">
          <NavLink to="/firstTimeUser">{t('header.first-time-users')}</NavLink>
          <NavLink to="/qa">{t('header.q-and-a')}</NavLink>
          <a href='#'>{t('header.contact-us')}</a>
          <span> | </span>
          <SwitchLanguage />
          <span> | </span>
          <Clock />
        </Container>
      </header>
      <header className="text-white bg-[#424242] lg:block hidden">
        <Container className="flex items-center gap-4">
          <Link to="/" className='mr-auto'>
            <img src={Logo} />
          </Link>
          { !isSignedIn && <button className="btn btn-sm btn-primary" onClick={navigate.bind(null, '/signin')}>{t('common.signIn')}</button> }
          { !isSignedIn && <button className="btn btn-sm btn-error" onClick={navigate.bind(null, '/signup')}>{t('common.signUp')}</button> }
          { isSignedIn && <span className="material-symbols-outlined cursor-pointer text-3xl" title={t('common.watch-list')}>favorite</span> }
          { isSignedIn && <span className="material-symbols-outlined cursor-pointer text-3xl" title={t('page.dashboard')} onClick={navigate.bind(null, '/dashboard')}>account_circle</span> }
          { isSignedIn && <span className="material-symbols-outlined cursor-pointer text-3xl" title={t('common.signOut')} onClick={navigate.bind(null, '/signout')}>logout</span> }
        </Container>
      </header>
      <header className="text-white bg-[#424242] lg:hidden sticky top-0 z-10">
        <Container className="flex items-center">
          <Link to="/" className="flex-1">
            <img src={Logo} className="h-16 mx-auto" />
          </Link>
          <span className="material-symbols-outlined p-4 absolute right-0" onClick={setOpenMenu.bind(null, true)}>
            menu
          </span>
          <Drawer
            open={openMenu}
            position="right"
            onClose={setOpenMenu.bind(null, false)}
          >
            <HeaderMobile
              onClose={setOpenMenu.bind(null, false)}
            />
          </Drawer>
        </Container>
      </header>
    </>
  )
}
const HeaderComponentForMobile = (props) => {
  const {
    isSignedIn,
    onClose
  } = props;

  const { t } = useTranslation();
  const [ user, setUser ] = React.useState({});
  const [ openLanguage, setOpenLanguage ] = React.useState(false);
  const { given_name = "guest", family_name = "user", email = "" } = user;

  const selectedLanguageName = useSelector(getSelectedLanguageName);

  React.useEffect(() => {
    axios.get('/api/v1/me', {
      skipErrorHandling: !isSignedIn
    })
      .then(({data}) => setUser(data))
  }, [
    isSignedIn
  ])

  return (
    <div className="bg-white text-base-content">
      <div className="grid grid-cols-[min-content_minmax(0,1fr)_min-content] items-center gap-x-2 border-b-2 border-base-200 px-4 py-2">
        <span className="material-symbols-outlined text-3xl row-span-2">
          account_circle
        </span>
        {
          isSignedIn &&
          <span className="text-xl truncate">
            {`${given_name} ${family_name}`}
          </span>
        }
        {
          isSignedIn &&
          <span className="col-start-2 truncate">
            ({email})
          </span>
        }
        <div className="leading-none col-start-3 row-start-1 row-span-2">
          <span className="material-symbols-outlined" onClick={onClose}>
            close
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-8 border-b-2 border-base-200 px-4 py-2">
        <div className="flex items-center justify-between" onClick={setOpenLanguage.bind(null, true)}>
          <span>
            Language: {selectedLanguageName}
          </span>
          <span className="material-symbols-outlined text-primary">
            arrow_forward_ios
          </span>
        </div>
        <Drawer
          open={openLanguage}
          position="right"
          onClose={setOpenLanguage.bind(null, false)}
        >
          <SwitchLanguageDrawer
            onClose={setOpenLanguage.bind(null, false)}
          />
        </Drawer>
      </div>
      {
        !isSignedIn &&
        <div className="border-b-2 border-base-200 px-4 py-2">
          <NavLink to="/signin" className="flex items-center gap-2">
            <span className="material-symbols-outlined">
              login
            </span>
            {t('common.signIn')}
          </NavLink>
        </div>
      }
      {
        !isSignedIn &&
        <div className="border-b-2 border-base-200 px-4 py-2">
          <NavLink to="/signup" className="flex items-center gap-2">
            <span className="material-symbols-outlined">
              person_add
            </span>
            {t('common.signUp')}
          </NavLink>
        </div>
      }
      {
        isSignedIn &&
        <div className="border-b-2 border-base-200 px-4 py-2">
          <NavLink to="/signout" className="flex items-center gap-2">
            <span className="material-symbols-outlined">
              logout
            </span>
            {t('common.signOut')}
          </NavLink>
        </div>
      }
      {
        isSignedIn &&
        <div className="border-b-2 border-base-200 px-4 py-2">
          <NavLink to="/dashboard/account" className="flex items-center gap-2">
            <span className="material-symbols-outlined">
              person
            </span>
            {t('page.my-account')}
          </NavLink>
        </div>
      }
      {
        isSignedIn &&
        <div className="border-b-2 border-base-200 px-4 py-2">
          <NavLink to="/dashboard/changePassword" className="flex items-center gap-2">
            <span className="material-symbols-outlined">
              password
            </span>
            {t('page.change-password')}
          </NavLink>
        </div>
      }
      <div className="border-b-2 border-base-200 px-4 py-2">
        <NavLink to="" className="flex items-center gap-2">
          <span className="material-symbols-outlined">
            notifications
          </span>
          {t('page.notification')}
        </NavLink>
      </div>
      <div className="border-b-2 border-base-200 px-4 py-2">
        <NavLink to="/firstTimeUser">{t('header.first-time-users')}</NavLink>
      </div>
      <div className="border-b-2 border-base-200 px-4 py-2">
        <NavLink to="/qa">{t('header.q-and-a')}</NavLink>
      </div>
      <div className="flex flex-col border-b-2 border-base-200 px-4 py-2">
        <span className="font-bold text-sm text-base-content/50 py-2">Company</span>
        <NavLink to="/about-us" className="link link-hover py-2">{t('footer.about-us')}</NavLink>
        <a className="link link-hover py-2">{t('footer.contact-us')}</a>
      </div>
      <div className="flex flex-col border-b-2 border-base-200 px-4 py-2">
        <span className="font-bold text-sm text-base-content/50 py-2">Legal</span>
        <NavLink to="/terms-of-use" className="link link-hover py-2">{t('page.terms-of-use')}</NavLink>
        <NavLink to="/privacy-and-cookie-policy" className="link link-hover py-2">{t('page.privacy-and-cookie-policy')}</NavLink>
        <NavLink to="/service-policy" className="link link-hover py-2">{t('page.service-policy')}</NavLink>
      </div>
      <div className="px-8 py-2">
        <a href="#" onClick={() => window.open('https://www.paypal.com/jp/webapps/mpp/logo/about-en','olcwhatispaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=900, height=700')}>
          <img src="https://www.paypalobjects.com/digitalassets/c/website/marketing/apac/jp/developer/CBT_logo_319_167.png" border="0" alt="PayPal（ペイパル）｜Mastercard, VISA, American Express, Discover, UnionPay, JCB" />
        </a>
      </div>
      <footer className="text-base-content flex flex-col justify-center items-center p-2">
        <img src={LogoBlack} />
        <p className="text-sm">
          © 2024 Unfolded Ltd. All rights reserved.
        </p>
      </footer>
    </div>
  )

}
export const Header = connect((state) => ({isSignedIn: state.user.isSignedIn}))(HeaderComponent)
export const HeaderMobile = connect((state) => ({isSignedIn: state.user.isSignedIn}))(HeaderComponentForMobile)
HeaderComponent.propTypes = {
  isSignedIn: PropTypes.bool
}
HeaderComponentForMobile.propTypes = {
  isSignedIn: PropTypes.bool,
  onClose: PropTypes.func
}
// vim:sw=2:ai
