import { useTranslation } from 'react-i18next';
import ShipSVG from '../assets/Ship.svg';

const flowItems = [
  {
    title: 'flow.search',
    iconName: 'search'
  },
  {
    title: 'flow.place-order',
    iconName: 'shopping_cart'
  },
  {
    title: 'flow.pay-1',
    iconName: 'credit_card'
  },
  {
    title: 'flow.items-received',
    iconName: 'warehouse'
  },
  {
    title: 'flow.provide-shipping-info',
    iconName: 'assignment_turned_in'
  },
  {
    title: 'flow.pay-2',
    iconName: 'credit_card'
  },
  {
    title: 'flow.sent',
    svg: ShipSVG
  },
]
export const Flow = () => {
  const { t } = useTranslation();

  return (
    <div className="relative border-l border-primary-content/60 ml-20 lg:ml-0 lg:border-l-0">
      <div className="px-8 h-px hidden lg:block">
        <div className="relative bg-grey h-full top-[64px] border border-primary-content/60"></div>
      </div>
      <ul className="relative flex flex-wrap justify-between items-start px-8 lg:ml-0 -ml-16">
        {
          flowItems.map((item, i) => (
            <li key={i} className="w-full lg:w-auto flex items-center lg:block mb-4 last:mb-0">
              <div className="lg:h-32 lg:w-32 w-16 h-16 border border-1 border-primary-content/60 rounded-full flex items-center justify-center bg-white">
                {
                  item.iconName &&
                  <span className="material-symbols-outlined text-[40px] text-primary-content/60">
                    {item.iconName}
                  </span>
                }
                {
                  item.svg &&
                    <img src={item.svg} className="h-full w-full p-2 lg:p-10" />
                }
              </div>
              <div className="lg:text-center ml-4 lg:ml-0 font-bold lg:font-normal my-2 w-32 text-primary-content/60">{t(item.title)}</div>
            </li>
          ))
        }
      </ul>
    </div>
  )
}
