import React from 'react';
import PropTypes from 'prop-types'
import { useGetFileQuery } from '../stores/services/file';
import { convertBase64ToBlob } from '../lib/utils';

export const FileLink = (props) => {
  const {
    srcId,
    ...rest
  } = props;

  const {
    data = null,
    error,
    isLoading
  } = useGetFileQuery(srcId);

  const [ url, setUrl ] = React.useState('');

  React.useEffect(() => {
    if (data === null) return;
    const _url = URL.createObjectURL(
      convertBase64ToBlob(data.content), {type: data.mime_type}
    )
    setUrl(_url);

    return () => {
      URL.revokeObjectURL(_url);
    }
  }, [data])

  if (data === null) return null;
  return <a href={url} download={data.name} {...rest} />
}
FileLink.propTypes = {
  srcId: PropTypes.string
}
