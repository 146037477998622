import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { switchLanguage } from "../stores/features/i18n/i18nSlice";
import { Languages } from "../stores/features/i18n/i18nSlice";
import { getSelectedLanguageName } from "../stores/features/i18n/i18nSlice";

const SwitchLanguageComponent = (props) => {
  const dispatch = useDispatch();
  const {
    lang
  } = props;

  const selectedLanguageName = useSelector(getSelectedLanguageName);

  return (
    <div className="dropdown dropdown-hover dropdown-end">
      <div tabIndex="0" role="button">{selectedLanguageName}</div>
      <ul tabIndex="0" className="dropdown-content menu bg-base-100 rounded-box z-10 w-52 p-2 shadow text-black">
        {
          Languages.map((lg, i) => (
            <li key={i}><a className={lang === lg.lang ? 'active': ''} onClick={() => dispatch(switchLanguage(lg.lang))}>{lg.name}</a></li>
          ))
        }
      </ul>
    </div>
  )
}
SwitchLanguageComponent.propTypes = {
  lang: PropTypes.string,
};
const SwitchLanguageDrawerComponent = (props) => {
  const dispatch = useDispatch();
  const {
    lang,
    onClose = () => {}
  } = props;
  // const languageName = Languages.find(l => l.lang === lang);
  //
  const onClick = (lg) => {
    dispatch(switchLanguage(lg.lang));
    onClose();
  }

  return (
    <div className="bg-white text-base-content min-h-full w-80">
      <div className="flex items-center border-b-2 border-base-200 p-4">
        <span>
          Language
        </span>
        <div className="leading-none ml-auto" onClick={onClose}>
          <span className="material-symbols-outlined">
            close
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-6 border-b-2 border-base-200 p-4">
        {
          Languages.map((lg, i) => (
            <div
              key={i}
              onClick={onClick.bind(null, lg)}
              className={[(lg.lang === lang ? 'font-bold text-primary' : '')].join(' ')}
            >
              {lg.name}
            </div>
          ))
        }
      </div>
    </div>
  )
}
SwitchLanguageDrawerComponent.propTypes = {
  lang: PropTypes.string,
  onClose: PropTypes.func
};
export const SwitchLanguage = connect((state) => ({ ...state.i18n }))(SwitchLanguageComponent);
export const SwitchLanguageDrawer = connect((state) => ({ ...state.i18n }))(SwitchLanguageDrawerComponent);
