import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../fetchBaseQuery';

export const fileApi = createApi({
    reducerPath: 'fileApi',
    baseQuery,
    tagTypes: [
        'File'
    ],
    endpoints: (builder) => ({
        getFiles: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/files';
                } else {
                    return '/files?' + new URLSearchParams(queries);
                }
            },
            providesTags: (result, error, arg) =>
            result
            ? [...result.map(({ id }) => ({ type: 'File', id })), 'File']
            : ['File'],
        }),
        countFiles: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/files/counts';
                } else {
                    return '/files/counts?' + new URLSearchParams(queries);
                }
            }
        }),
        getFile: builder.query({
            query: (id) => `/file/${id}`,
            providesTags: (result, error, arg) => [{type: 'File', id: result.id}]
        }),
        createFile: builder.mutation({
            query: (body) => ({
                url: `/file`,
                method: 'POST',
                body,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: ['File']
        }),
        updateFile: builder.mutation({
            query: ({ id, ...patch }) => ({
                url: `/file/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'File', id: arg.id}]
        }),
        deleteFile: builder.mutation({
            query: (id) => ({
                url: `/file/${id}`,
                method: 'DELETE'
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'File', id: arg.id}]
        }),
    }),
})

export const {
    useGetFilesQuery,
    useCountFilesQuery,
    useGetFileQuery,
    useCreateFileMutation,
    useUpdateFileMutation,
    useDeleteFileMutation,
} = fileApi
