import LogoBlack from '../assets/Logo_black.svg';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      <footer className="footer text-base-content p-10">
        <nav>
          <h6 className="footer-title">Company</h6>
          <NavLink to="/about-us" className="link link-hover">{t('footer.about-us')}</NavLink>
          <a className="link link-hover">{t('footer.contact-us')}</a>
        </nav>
        <nav>
          <h6 className="footer-title">Legal</h6>
          <NavLink to="/terms-of-use" className="link link-hover">{t('page.terms-of-use')}</NavLink>
          <NavLink to="/privacy-and-cookie-policy" className="link link-hover">{t('page.privacy-and-cookie-policy')}</NavLink>
          <NavLink to="/service-policy" className="link link-hover">{t('page.service-policy')}</NavLink>
        </nav>
        <nav className="">
          <a href="#" onClick={() => window.open('https://www.paypal.com/jp/webapps/mpp/logo/about-en','olcwhatispaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=900, height=700')}>
            <img src="https://www.paypalobjects.com/digitalassets/c/website/marketing/apac/jp/developer/CBT_logo_319_167.png" border="0" alt="PayPal（ペイパル）｜Mastercard, VISA, American Express, Discover, UnionPay, JCB" />
          </a>
        </nav>
      </footer>
      <footer className="footer text-base-content border-base-300 border-t px-10 py-4">
        <aside className="grid-flow-row lg:grid-flow-col items-center mx-auto lg:m-0">
          <img src={LogoBlack} />
          <p>
            © 2024 Unfolded Ltd. All rights reserved.
          </p>
        </aside>
      </footer>

    </>
  )
}
Footer.propTypes = {}
