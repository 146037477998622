import React from "react";
import { CommonLayout } from "../components/layouts/CommonLayout"
import { Container } from "../components/layouts/Container"
import { Box } from "../components/layouts/Box"
import { PageTitle } from "../components/styled/PageTitle";

const getNumberFormatted = (num, currency, locale) => {
  return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(
    num,
  )
}
export const EstimatePage = () => {

  const [ selectedTab, setSelectedTab ] = React.useState(0);
  const [ tabs ] = React.useState([
    { id: 0, name: 'General' },
    { id: 1, name: 'For Tires' },
    { id: 2, name: 'By Shipping Rank' },
  ]);
  const [ selectedRank, setSelectedRank ] = React.useState(0);
  const [ ranks ] = React.useState([
    { id: 0, name: 'Rank A' },
    { id: 1, name: 'Rank B' },
    { id: 2, name: 'Rank C' },
    { id: 3, name: 'Rank D' },
    { id: 4, name: 'Rank E' },
    { id: 5, name: 'Rank F' },
  ]);
  const [ openResult, setOpenResult ] = React.useState(false);

  return (
    <CommonLayout>
      <section className="bg-base-200 py-4">
        <Container>
          <PageTitle>
            Shipping Fee Calculator
          </PageTitle>
          <Box className="p-12">
            <h2 className="text-lg font-bold mb-6">Estimation</h2>
            <div className="grid grid-cols-4 gap-y-2">
              <div className="flex items-center justify-start">
                Retail price
              </div>
              <div className="col-span-3">
                <label className="input input-bordered flex items-center gap-2 focus-within:outline-none">
                  <span className="material-symbols-outlined text-lg">
                    currency_yen
                  </span>
                  <input type="number" className="grow" />
                </label>
              </div>
              <div className="flex items-center justify-start">
                Size / Weight
              </div>
              <div className="col-span-3">
                <div role="tablist" className="tabs tabs-bordered my-2">
                  {
                    tabs.map(tab => (
                      <a
                        key={tab.id}
                        role="tab"
                        className={"tab " + (selectedTab === tab.id ? 'tab-active !border-primary' : '')}
                        onClick={setSelectedTab.bind(null, tab.id)}
                      >
                        {tab.name}
                      </a>
                    ))
                  }
                </div>
                <div className={"flex items-center gap-2 w-full " + (selectedTab === 0 ? '' : 'hidden')}>
                  <label className="input input-bordered w-full flex items-center gap-2 focus-within:outline-none">
                    <input type="number" placeholder="Length" className="w-full" />
                    <span className="text-lg">
                      cm
                    </span>
                  </label>
                  <label className="input input-bordered w-full flex items-center gap-2 focus-within:outline-none">
                    <input type="number" placeholder="Width" className="w-full" />
                    <span className="text-lg">
                      cm
                    </span>
                  </label>
                  <label className="input input-bordered w-full flex items-center gap-2 focus-within:outline-none">
                    <input type="number" placeholder="Height" className="w-full" />
                    <span className="text-lg">
                      cm
                    </span>
                  </label>
                  <label className="input input-bordered w-full flex items-center gap-2 focus-within:outline-none">
                    <input type="number" placeholder="Weight" className="w-full" />
                    <span className="text-lg">
                      kg
                    </span>
                  </label>
                </div>
                <div className={"flex items-center gap-2 w-full " + (selectedTab === 1 ? '' : 'hidden')}>
                  <label className="input input-bordered w-full flex items-center gap-2 focus-within:outline-none">
                    <input type="number" placeholder="Width" className="w-full" />
                    <span className="text-lg">
                      cm
                    </span>
                  </label>
                  <label className="input input-bordered w-full flex items-center gap-2 focus-within:outline-none">
                    <input type="number" placeholder="Ratio" className="w-full" />
                    <span className="text-lg">
                      %
                    </span>
                  </label>
                  <label className="input input-bordered w-full flex items-center gap-2 focus-within:outline-none">
                    <input type="number" placeholder="Diamater" className="w-full" />
                    <span className="text-lg">
                      inch
                    </span>
                  </label>
                  <label className="input input-bordered w-full flex items-center gap-2 focus-within:outline-none">
                    <input type="number" placeholder="Weight" className="w-full" />
                    <span className="text-lg">
                      kg
                    </span>
                  </label>
                </div>
                <div className={"flex items-center gap-2 w-full " + (selectedTab === 2 ? '' : 'hidden')}>
                  <div className="join join-vertical w-full max-w-64">
                    {
                      ranks.map(rank => (
                        <button
                          key={rank.id}
                          className={"btn join-item " + (selectedRank === rank.id ? 'btn-primary': '')}
                          onClick={setSelectedRank.bind(null, rank.id)}
                        >
                          {rank.name}
                          <div className="tooltip" data-tip="Steering, Emblem, Gear Knob, Defi Meter, Injectors.. etc">
                            <span className="material-symbols-outlined text-base">
                              info
                            </span>
                          </div>
                        </button>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center gap-4 my-4">
              <button className="btn btn-primary w-64" onClick={setOpenResult.bind(null, true)}>
                <span className="material-symbols-outlined">
                  calculate
                </span>
                Calculate
              </button>
              <button className="btn btn-error btn-outline w-32" onClick={setOpenResult.bind(null, false)}>
                <span className="material-symbols-outlined">
                  restart_alt
                </span>
                Reset
              </button>
            </div>

            <div className="text-sm font-semibold">
              Please note
            </div>
            <div className="text-sm">
              * Payment fees are not charged to customers even if they are incurred on purchases in the country of purchase.
            </div>
            <div className="text-sm">
              * Payment fees are not charged to customers even if they are incurred on purchases in the country of purchase.
            </div>
            <div className="text-sm">
              * Payment fees are not charged to customers even if they are incurred on purchases in the country of purchase.
            </div>
            <div className="text-sm">
              * Payment fees are not charged to customers even if they are incurred on purchases in the country of purchase.
            </div>
            <div className="divider"></div>

            <div className={openResult ? '' : 'hidden'}>
              <h2 className="text-lg font-bold mb-6">Estimated shipping cost</h2>
              <div className="text-md font-bold my-6">Charge 1</div>
              <div className="grid grid-cols-2 border border-base-300">
                <div className="p-3 border-b border-r border-base-300 bg-base-200">
                  Retail price
                </div>
                <div className="p-3 border-b border-base-300">
                  {getNumberFormatted(20000, 'JPY', 'ja')}
                </div>
                <div className="p-3 border-r border-base-300 bg-base-200 font-bold">
                  Sub-total of Charge 1
                </div>
                <div className="p-3 font-bold">
                  {getNumberFormatted(20000, 'JPY', 'ja')} (≒ {getNumberFormatted(20000, 'MNT', 'mn')})
                </div>
              </div>
              <div className="text-md font-bold my-6">Charge 2</div>
              <div className="grid grid-cols-2 border border-base-300">
                <div className="p-3 border-b border-r border-base-300 bg-base-200">
                  International Shipping
                </div>
                <div className="p-3 border-b border-base-300">
                  <div className="form-control">
                    <label className="label cursor-pointer justify-start gap-2">
                      <input type="radio" name="radio-10" className="radio radio-primary" />
                      <span className="label-text w-24">LCL</span>
                      <span className="label-text">
                        {getNumberFormatted(20000, 'JPY', 'ja')} (≒ {getNumberFormatted(20000, 'MNT', 'mn')})
                      </span>
                    </label>
                    <label className="label cursor-pointer justify-start gap-2">
                      <input type="radio" name="radio-10" className="radio radio-primary" />
                      <span className="label-text w-24">Sagawa</span>
                      <span className="label-text">
                        {getNumberFormatted(20000, 'JPY', 'ja')} (≒ {getNumberFormatted(20000, 'MNT', 'mn')})
                      </span>
                    </label>
                  </div>
                </div>
                <div className="p-3 border-b border-r border-base-300 bg-base-200">
                  Handlding fee
                </div>
                <div className="p-3 border-b border-base-300">
                  {getNumberFormatted(500, 'JPY', 'ja')} / one
                </div>
                <div className="p-3 border-b border-r border-base-300 bg-base-200">
                  Payment fee
                </div>
                <div className="p-3 border-b border-base-300">
                  Free
                </div>
                <div className="p-3 border-b border-r border-base-300 bg-base-200">
                  Domestic delivery fee
                </div>
                <div className="p-3 border-b border-base-300">
                  {getNumberFormatted(700, 'JPY', 'ja')}
                </div>
                <div className="p-3 border-b border-r border-base-300 bg-base-200">
                  Export Clearance Fee
                </div>
                <div className="p-3 border-b border-base-300">
                  {getNumberFormatted(0, 'JPY', 'ja')}
                </div>
                <div className="p-3 border-r border-base-300 bg-base-200 font-bold">
                  Sub-total of Charge 2
                </div>
                <div className="p-3 font-bold">
                  {getNumberFormatted(15797, 'JPY', 'ja')} (≒ {getNumberFormatted(15797, 'MNT', 'mn')})
                </div>
              </div>
              <div className="text-md font-bold my-6">Total</div>
              <div>
                <div className="text-end text-primary text-2xl font-bold">
                  {getNumberFormatted(50050, 'JPY', 'ja')}
                </div>
                <div className="text-end text-lg">
                  (≒ {getNumberFormatted(50050, 'MNT', 'mn')})
                </div>
              </div>
            </div>
          </Box>
        </Container>
      </section>
    </CommonLayout>
  )
}
