import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../fetchBaseQuery';

export const carMakeApi = createApi({
    reducerPath: 'carMakeApi',
    baseQuery,
    tagTypes: [
        'CarMake'
    ],
    endpoints: (builder) => ({
        getCarMakes: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/carMakes';
                } else {
                    return '/carMakes?' + new URLSearchParams(queries);
                }
            },
            providesTags: (result, error, arg) =>
            result
            ? [...result.map(({ id }) => ({ type: 'CarMake', id })), 'CarMake']
            : ['CarMake'],
        }),
        countCarMakes: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/carMakes/counts';
                } else {
                    return '/carMakes/counts?' + new URLSearchParams(queries);
                }
            }
        }),
        getCarMake: builder.query({
            query: (id) => `/carMake/${id}`,
            providesTags: (result, error, arg) => [{type: 'CarMake', id: result.id}]
        }),
        createCarMake: builder.mutation({
            query: (body) => ({
                url: `/carMake`,
                method: 'POST',
                body,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: ['CarMake']
        }),
        updateCarMake: builder.mutation({
            query: ({ id, ...patch }) => ({
                url: `/carMake/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'CarMake', id: arg.id}]
        }),
        deleteCarMake: builder.mutation({
            query: (id) => ({
                url: `/carMake/${id}`,
                method: 'DELETE'
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'CarMake', id: arg.id}]
        }),
    }),
})

export const {
    useGetCarMakesQuery,
    useCountCarMakesQuery,
    useGetCarMakeQuery,
    useCreateCarMakeMutation,
    useUpdateCarMakeMutation,
    useDeleteCarMakeMutation,
} = carMakeApi
