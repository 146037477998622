import React from "react";
import { useTranslation } from 'react-i18next';

export const ChooseListDrawer = (props) => {
  const {
    label = "",
    labelProp = "name",
    valueProp = "value",
    options = [],
    value = null,
    onSelect = () => {},
    onChangePage = () => {},
    onOpen = () => {},
    onClose = () => {},
    disabled = false
  } = props;

  const { t } = useTranslation();
  const [ show, setShow ] = React.useState(false);
  const [ _options, _setOptions ] = React.useState([]);
  const [ _parent, _setParent ] = React.useState(null);

  const _onSelectItem = ( _option) => {
    onSelect(_option);
    setShow(false);
  }

  const _onChangePage = (direction, _option) => {
    if (direction === "next") {
      if (_option.has_children) {
        const { parent, children } = onChangePage(direction, _option);
        _setParent({
          ...parent
        })
        _setOptions([
          ...children
        ])
      } else {
        _onSelectItem(_option);
      }
    } else if(direction === "prev") {
      if (_option) {
        const { parent, children } = onChangePage(direction, _option);
        _setParent(parent === undefined ? null : { ...parent })
        _setOptions([
          ...children
        ])
      } else {
        setShow(false);
      }
    } else {
      _onSelectItem(_option);
      setShow(false);
    }
  }

  React.useEffect(() => {
    if (show) {
      onOpen();
    } else {
      onClose();
      _setOptions([...options])
      _setParent(null)
    }
  }, [
    show
  ])

  React.useEffect(() => {
    _setOptions([...options])
  }, [
    options
  ])

  return (
    <>
      <label className={"flex items-center px-2 py-4 border-b-2 border-base-300 " + (disabled ? 'text-gray-400' : '')} onClick={() => disabled || setShow(true)}>
        <span>{value ? (value[labelProp] || value.name_en) : label}</span>
        {
          value && value.imageUrl &&
          <img src={value.imageUrl} className="ml-2 h-10 w-auto" />
        }
       <span className={"material-symbols-outlined ml-auto " + (disabled ? '' : 'text-primary')}>
          arrow_forward_ios
        </span>
      </label>
      <div className={"bg-white transition duration-300 h-full w-full overflow-y-auto z-20 fixed left-0 top-0 translate-x-full " + (show ? "!translate-x-0" : "")}>
        <div className="sticky top-0 flex items-center justify-center px-2 py-4 border-b-2 border-base-300 bg-white">
          <label className="material-symbols-outlined absolute left-0 top-0 h-full flex items-center px-4" onClick={_onChangePage.bind(null, "prev", _parent)}>
            arrow_back_ios
          </label>
          <span>{_parent ? (_parent[labelProp] || _parent.name_en) : label}</span>
        </div>
        {
          _parent !== null &&
          <div className="flex items-center justify-between px-2 py-4 border-b-2 border-base-300" onClick={_onChangePage.bind(null, null, _parent)}>
            <span>{t('common.all')}</span>
            {
              value && _parent && _parent[valueProp] === value[valueProp] &&
              <span className="material-symbols-outlined text-primary">
                check
              </span>
            }
          </div>
        }
        {
          _options.map(
            (option, i) => (
              <div key={i} className="flex items-center px-2 py-4 border-b-2 border-base-300" onClick={_onChangePage.bind(null, "next", option)}>
                <span>{option[labelProp] || option.name_en}</span>
                {
                  option.imageUrl &&
                  <img src={option.imageUrl} className="ml-2 h-10 w-auto" />
                }
                {
                  value && !option.has_children && option[valueProp] === value[valueProp] &&
                  <span className="material-symbols-outlined text-primary ml-auto">
                    check
                  </span>
                }
                {
                  option.has_children &&
                  <span className="material-symbols-outlined text-primary ml-auto">
                    arrow_forward_ios
                  </span>
                }
              </div>
            )
          )
        }
      </div>
    </>
  )
}
// vim:sw=2:ai
