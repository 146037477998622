import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../fetchBaseQuery';

export const categoryForFilterApi = createApi({
    reducerPath: 'categoryForFilterApi',
    baseQuery,
    tagTypes: [
        'CategoryForFilter'
    ],
    endpoints: (builder) => ({
        getCategoryForFilters: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/categoryForFilters';
                } else {
                    return '/categoryForFilters?' + new URLSearchParams(queries);
                }
            },
            providesTags: (result, error, arg) =>
            result
            ? [...result.map(({ id }) => ({ type: 'CategoryForFilter', id })), 'CategoryForFilter']
            : ['CategoryForFilter'],
        }),
        countCategoryForFilters: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/categoryForFilters/counts';
                } else {
                    return '/categoryForFilters/counts?' + new URLSearchParams(queries);
                }
            }
        }),
        getCategoryForFilter: builder.query({
            query: (id) => `/categoryForFilter/${id}`,
            providesTags: (result, error, arg) => [{type: 'CategoryForFilter', id: result.id}]
        }),
        createCategoryForFilter: builder.mutation({
            query: (body) => ({
                url: `/categoryForFilter`,
                method: 'POST',
                body,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: ['CategoryForFilter']
        }),
        updateCategoryForFilter: builder.mutation({
            query: ({ id, ...patch }) => ({
                url: `/categoryForFilter/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'CategoryForFilter', id: arg.id}]
        }),
        deleteCategoryForFilter: builder.mutation({
            query: (id) => ({
                url: `/categoryForFilter/${id}`,
                method: 'DELETE'
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'CategoryForFilter', id: arg.id}]
        }),
    }),
})

export const {
    useGetCategoryForFiltersQuery,
    useCountCategoryForFiltersQuery,
    useGetCategoryForFilterQuery,
    useCreateCategoryForFilterMutation,
    useUpdateCategoryForFilterMutation,
    useDeleteCategoryForFilterMutation,
} = categoryForFilterApi
