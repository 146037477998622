import React from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { ChooseListDrawer } from "./ChooseListDrawer";
import { useGetCategoryForFiltersQuery } from "../stores/services/categoryForFilter";
import { useTranslation } from 'react-i18next';

const CategoryChooseListDrawerComponent = (props) => {

  const {
    lang,
    categoryGroupId,
    value,
    onSelect = () => {},
    ...rest
  } = props;
  const { t } = useTranslation();

  const {
    data: categories = [],
    error: categoriesError,
    isLoading: categoriesLoading,
  } = useGetCategoryForFiltersQuery({
    scope: 'byCategoryGroup,' + categoryGroupId
  });

  const [ _categories, _setCategories ] = React.useState([]);
  const [ _rootCategories, _setRootCategories ] = React.useState([]);

  const onChangePage = React.useCallback((direction, _option) => {
    if (direction === "prev") {
      return {
        parent: _categories.find(
          o => o.id === _option.parent_category_id
        ),
        children: _categories.filter(
          o => o.parent_category_id === _option.parent_category_id
        )
      }
    } else if (direction === "next") {
      return {
        parent: _option,
        children: _categories.filter(
          o => o.parent_category_id === _option.id
        )
      }
    }
  }, [
    _categories
  ])

  React.useEffect(() => {
    _setCategories([
      ...categories
      .map(c => c.Category)
    ])

    const ids = categories
      .map(c => c.Category.id);
    _setRootCategories([
      ...categories.filter(
        c => ids.includes(c.Category.parent_category_id) === false
      ).map(c => c.Category)
    ])
  }, [
    categories
  ])

  return (
    <ChooseListDrawer
      label={t('chooseListDrawer.choose-category')}
      value={value}
      options={_rootCategories}
      onSelect={onSelect}
      onChangePage={onChangePage}
      labelProp={`name_${lang}`}
      valueProp="id"
      {...rest}
    />
  )
}
CategoryChooseListDrawerComponent.propTypes = {
  categoryGroupId: PropTypes.string,
  lang: PropTypes.string,
  value: PropTypes.any,
  onSelect: PropTypes.func
}
export const CategoryChooseListDrawer = connect((state) => ({ ...state.i18n }))(CategoryChooseListDrawerComponent);
// vim:sw=2:ai
