import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../fetchBaseQuery';

export const orderItemRequestedApi = createApi({
    reducerPath: 'orderItemRequestedApi',
    baseQuery,
    tagTypes: [
        'OrderItemRequested'
    ],
    endpoints: (builder) => ({
        getOrderItemRequesteds: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/orderItemRequesteds';
                } else {
                    return '/orderItemRequesteds?' + new URLSearchParams(queries);
                }
            },
            providesTags: (result, error, arg) =>
            result
            ? [...result.map(({ id }) => ({ type: 'OrderItemRequested', id })), 'OrderItemRequested']
            : ['OrderItemRequested'],
        }),
        countOrderItemRequesteds: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/orderItemRequesteds/counts';
                } else {
                    return '/orderItemRequesteds/counts?' + new URLSearchParams(queries);
                }
            }
        }),
        getOrderItemRequested: builder.query({
            query: (id) => `/orderItemRequested/${id}`,
            providesTags: (result, error, arg) => [{type: 'OrderItemRequested', id: result.id}]
        }),
        createOrderItemRequested: builder.mutation({
            query: (body) => ({
                url: `/orderItemRequested`,
                method: 'POST',
                body,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: ['OrderItemRequested']
        }),
        updateOrderItemRequested: builder.mutation({
            query: ({ id, ...patch }) => ({
                url: `/orderItemRequested/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'OrderItemRequested', id: arg.id}]
        }),
        deleteOrderItemRequested: builder.mutation({
            query: (id) => ({
                url: `/orderItemRequested/${id}`,
                method: 'DELETE'
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'OrderItemRequested', id: arg.id}]
        }),
    }),
})

export const {
    useGetOrderItemRequestedsQuery,
    useCountOrderItemRequestedsQuery,
    useGetOrderItemRequestedQuery,
    useCreateOrderItemRequestedMutation,
    useUpdateOrderItemRequestedMutation,
    useDeleteOrderItemRequestedMutation,
} = orderItemRequestedApi
