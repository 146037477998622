import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../fetchBaseQuery';

export const categoryApi = createApi({
    reducerPath: 'categoryApi',
    baseQuery,
    tagTypes: [
        'Category'
    ],
    endpoints: (builder) => ({
        getCategorys: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/categorys';
                } else {
                    return '/categorys?' + new URLSearchParams(queries);
                }
            },
            providesTags: (result, error, arg) =>
            result
            ? [...result.map(({ id }) => ({ type: 'Category', id })), 'Category']
            : ['Category'],
        }),
        countCategorys: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/categorys/counts';
                } else {
                    return '/categorys/counts?' + new URLSearchParams(queries);
                }
            }
        }),
        getCategory: builder.query({
            query: (id) => `/category/${id}`,
            providesTags: (result, error, arg) => [{type: 'Category', id: result.id}]
        }),
        createCategory: builder.mutation({
            query: (body) => ({
                url: `/category`,
                method: 'POST',
                body,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: ['Category']
        }),
        updateCategory: builder.mutation({
            query: ({ id, ...patch }) => ({
                url: `/category/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'Category', id: arg.id}]
        }),
        deleteCategory: builder.mutation({
            query: (id) => ({
                url: `/category/${id}`,
                method: 'DELETE'
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'Category', id: arg.id}]
        }),
    }),
})

export const {
    useGetCategorysQuery,
    useCountCategorysQuery,
    useGetCategoryQuery,
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
    useDeleteCategoryMutation,
} = categoryApi
