import PropTypes from 'prop-types';

export const InputUserName = (props) => {
  const {
    value = "",
    onChange = () => {},
    autoComplete = "",
    ...rest
  } = props;

  return (
    <label className="input input-bordered flex items-center gap-2 focus-within:outline-none">
      <span className="material-symbols-outlined text-base text-primary-content/50">
        mail
      </span>
      <input
        type="email"
        className="grow"
        minLength="1"
        maxLength="255"
        value={value}
        onChange={onChange}
        autoComplete={autoComplete}
        required
        {...rest}
      />
    </label>
  )
}
InputUserName.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  autoComplete: PropTypes.string
}
