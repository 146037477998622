import React from "react";
import { useGetNewssQuery } from "../stores/services/news";
import moment from "moment";

export const News = () => {
  const {
    data = [],
    error,
    isLoading
  } = useGetNewssQuery({limit: 2, page: 1});

  return (
    <div className="news">
      <div className="flex">
        <div className="flex-1 w-full">
          {data.map(news => (
            <div key={news.id} className="flex flex-nowrap gap-2 w-full">
              <span className="inline-block flex-none">{moment(news.publishedAt).format('MMMM DD')}</span>
              <a href="#" className="text-primary flex-1 truncate">{news.title}</a>
            </div>
          ))}
        </div>
        <div className="divider divider-horizontal lg:block hidden"></div>
        <a href="#" className="hidden lg:flex items-center text-primary gap-2">
          Show more
          <span className="material-symbols-outlined text-base">
            arrow_forward_ios
          </span>
        </a>
      </div>
    </div>
  )
}
News.propTypes = {}
