import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../fetchBaseQuery';

export const orderApi = createApi({
    reducerPath: 'orderApi',
    baseQuery,
    tagTypes: [
        'Order'
    ],
    endpoints: (builder) => ({
        getOrders: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/orders';
                } else {
                    return '/orders?' + new URLSearchParams(queries);
                }
            },
            providesTags: (result, error, arg) =>
            result
            ? [...result.map(({ id }) => ({ type: 'Order', id })), 'Order']
            : ['Order'],
        }),
        countOrders: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/orders/counts';
                } else {
                    return '/orders/counts?' + new URLSearchParams(queries);
                }
            }
        }),
        getOrder: builder.query({
            query: (id) => `/order/${id}`,
            providesTags: (result, error, arg) => [{type: 'Order', id: result.id}]
        }),
        createOrder: builder.mutation({
            query: (body) => ({
                url: `/order`,
                method: 'POST',
                body,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: ['Order']
        }),
        updateOrder: builder.mutation({
            query: ({ id, ...patch }) => ({
                url: `/order/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'Order', id: arg.id}]
        }),
        deleteOrder: builder.mutation({
            query: (id) => ({
                url: `/order/${id}`,
                method: 'DELETE'
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'Order', id: arg.id}]
        }),
    }),
})

export const {
    useGetOrdersQuery,
    useCountOrdersQuery,
    useGetOrderQuery,
    useCreateOrderMutation,
    useUpdateOrderMutation,
    useDeleteOrderMutation,
} = orderApi
