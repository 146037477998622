import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../fetchBaseQuery';

export const shippingAddressApi = createApi({
    reducerPath: 'shippingAddressApi',
    baseQuery,
    tagTypes: [
        'ShippingAddress'
    ],
    endpoints: (builder) => ({
        getShippingAddresss: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/shippingAddresss';
                } else {
                    return '/shippingAddresss?' + new URLSearchParams(queries);
                }
            },
            providesTags: (result, error, arg) =>
            result
            ? [...result.map(({ id }) => ({ type: 'ShippingAddress', id })), 'ShippingAddress']
            : ['ShippingAddress'],
        }),
        countShippingAddresss: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/shippingAddresss/counts';
                } else {
                    return '/shippingAddresss/counts?' + new URLSearchParams(queries);
                }
            }
        }),
        getShippingAddress: builder.query({
            query: (id) => `/shippingAddress/${id}`,
            providesTags: (result, error, arg) => [{type: 'ShippingAddress', id: result.id}]
        }),
        createShippingAddress: builder.mutation({
            query: (body) => ({
                url: `/shippingAddress`,
                method: 'POST',
                body,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: ['ShippingAddress']
        }),
        updateShippingAddress: builder.mutation({
            query: ({ id, ...patch }) => ({
                url: `/shippingAddress/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'ShippingAddress', id: arg.id}]
        }),
        deleteShippingAddress: builder.mutation({
            query: (id) => ({
                url: `/shippingAddress/${id}`,
                method: 'DELETE'
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'ShippingAddress', id: arg.id}]
        }),
    }),
})

export const {
    useGetShippingAddresssQuery,
    useCountShippingAddresssQuery,
    useGetShippingAddressQuery,
    useCreateShippingAddressMutation,
    useUpdateShippingAddressMutation,
    useDeleteShippingAddressMutation,
} = shippingAddressApi
