import { CommonLayout } from "../components/layouts/CommonLayout"
import { Container } from "../components/layouts/Container"
import { Box } from "../components/layouts/Box"
import { useTranslation } from 'react-i18next';
import { PageTitle } from "../components/styled/PageTitle"

export const ServicePolicyPage = () => {
  const { t } = useTranslation();

  return (
    <CommonLayout>
      <section className="bg-base-200">
        <Container>
          <PageTitle>
            {t('page.service-policy')}
          </PageTitle>
          <Box className="p-4">
            <h2 className="text-lg text-center font-bold">TsunoMarket Service Policy</h2>
            <p className="py-2">
              Before delving into the details of how to address issues that may arise during import, it is essential to fully understand and acknowledge the principles of Tsuno Market's services.
            </p>
            <ul className="list-decimal list-inside py-2">
              <li>
                Tsuno Market is neither a sales platform, store, nor auction site. We act as an intermediary between you and the seller or shop.
              </li>
              <li>
                Our services are based on three elements: purchasing on behalf of the customer, storing the customer's items in our warehouse, and internationally shipping the items to the designated address. We are committed to ensuring this process runs smoothly.
              </li>
              <li>
                In case any issues arise with third parties (Japanese shops, shipping companies, customs, etc.), Tsuno Market will not take responsibility or intervene, except when it is clearly an issue caused by the shipping company. Unfortunately, for issues attributable to sellers, regardless of when or why they occur, we cannot dispute them.
              </li>
              <li>
                Importantly, if you are dissatisfied with the outcome of issues involving third parties, you agree not to initiate any compulsory legal action against Tsuno Market through payment or banking institutions. This commitment is part of the Terms of Service that you agree to upon using our services.
              </li>
            </ul>
            <h2 className="text-lg font-bold hidden">Specific Examples</h2>
            <div className="w-full overflow-x-auto hidden">
              <table className="border-collapse">
                <thead>
                  <tr>
                    <th className="border border-slate-600">Issue</th>
                    <th className="border border-slate-600">Responsibility</th>
                    <th className="border border-slate-600">Result</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border border-slate-600 whitespace-nowrap p-2">My item doesn't match the original description.</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">Japanese vendor or shop</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">No claims or refunds.</td>
                  </tr>
                  <tr>
                    <td className="border border-slate-600 whitespace-nowrap p-2">My item is not genuine.</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">Japanese vendor or shop</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">No claims or refunds.</td>
                  </tr>
                  <tr>
                    <td className="border border-slate-600 whitespace-nowrap p-2">The seller turned out to be a fraud (no item delivered and no contact).</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">Japanese vendor or shop</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">No claims or refunds.</td>
                  </tr>
                  <tr>
                    <td className="border border-slate-600 whitespace-nowrap p-2">I want to cancel the item after my purchase request is accepted.</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">Service recipient (you)</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">No claims or refunds.</td>
                  </tr>
                  <tr>
                    <td className="border border-slate-600 whitespace-nowrap p-2">International shipping: Package delayed.</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">Shipping company</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">TsunoMarket will mediate, no refunds for delays.</td>
                  </tr>
                  <tr>
                    <td className="border border-slate-600 whitespace-nowrap p-2">My item hasn't arrived at TsunoMarket's warehouse.</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">Japanese vendor or shop</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">We monitor and intervene when necessary.</td>
                  </tr>
                  <tr>
                    <td className="border border-slate-600 whitespace-nowrap p-2">International shipping: Item arrived damaged.</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">Shipping company</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">Partial or full refund depending on damage.</td>
                  </tr>
                  <tr>
                    <td className="border border-slate-600 whitespace-nowrap p-2">Import or customs fees are too high.</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">Service recipient (you)</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">Fees are the customer’s responsibility.</td>
                  </tr>
                  <tr>
                    <td className="border border-slate-600 whitespace-nowrap p-2">I can't pay for the shipping, but the item is purchased.</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">Service recipient (you)</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">Reminder of terms and cancellation policy.</td>
                  </tr>
                  <tr>
                    <td className="border border-slate-600 whitespace-nowrap p-2">Item lost during storage.</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">TsunoMarket</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">Full refund.</td>
                  </tr>
                  <tr>
                    <td className="border border-slate-600 whitespace-nowrap p-2">Error in package contents.</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">TsunoMarket</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">Missing items will be re-sent.</td>
                  </tr>
                  <tr>
                    <td className="border border-slate-600 whitespace-nowrap p-2">Label error caused package to return to Japan.</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">TsunoMarket</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">Missing items will be re-sent.</td>
                  </tr>
                  <tr>
                    <td className="border border-slate-600 whitespace-nowrap p-2">Incorrect delivery address caused package return to Japan.</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">Service recipient (you)</td>
                    <td className="border border-slate-600 whitespace-nowrap p-2">Return and reshipping fees charged to the customer.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="py-2 text-error">
              Even if the seller or shop has a cancellation policy in place, once Tsuno Market has purchased the item, cancellations are not possible under any circumstances.
            </p>
          </Box>
        </Container>
      </section>
    </CommonLayout>
  )
}
