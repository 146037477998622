import PropTypes from 'prop-types';
import { useGetCarModelsQuery } from "../stores/services/carModel";

export const SelectCarModel = (props) => {

  const {
    carMakeId = -1,
      ...rest
  } = props;
  const {
    data = [],
    error,
    isLoading
  } = useGetCarModelsQuery({
    carMakeId
  });

  return (
    <select className="select select-bordered w-full focus:outline-none" {...rest}>
      <option value="" selected>--</option>
      {
        data.map(d => (
          <option key={d.id} value={d.name_ja}>{d.name_en}</option>
        ))
      }
    </select>
  )
}
SelectCarModel.propTypes = {
  carMakeId: PropTypes.number
}
// vim:sw=2:ai
