import React from "react";
import axios from "../../lib/axios";
import { Box } from "../../components/layouts/Box";
import { DashboardLayout } from "../../components/layouts/DashboardLayout";
import { Cube } from "../../components/Cube";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export const DashBoardPage = () => {

  const [ user, setUser ] = React.useState({});
  const { t } = useTranslation();

  React.useEffect(() => {
    axios.get('/api/v1/me')
      .then(({data}) => setUser({...data}))
  }, [])

  return (
    <DashboardLayout pageTitle={t('page.dashboard')}>
      <div className="col-span-3">
        <Box className="w-full p-4">
          <div className="flex gap-4">
            <div>
              <span className="material-symbols-outlined text-[3rem]">
                account_circle
              </span>
            </div>
            <div className="flex gap-2 items-center flex-1 min-w-0 lg:text-lg text-base font-semibold">
              <span className="flex-1 min-w-0 truncate">
                {`${user.given_name} ${user.family_name}`}
              </span>
            </div>
          </div>
        </Box>
      </div>
      <div className="col-span-3 hidden">
        <Box className="w-full">
          <div className="p-4 bg-white rounded-lg">
            <div className="flex items-center mb-6 lg:mb-4">
              <span className="material-symbols-outlined text-base">
                add
              </span>
              <span className="font-bold text-base ml-1">Add an item to Watch List</span>
            </div>
            <div className="text-center">
              <input type="text" placeholder="Enter item URL to add to Watch List" className="input input-bordered w-full focus:outline-none" />
              <button className="btn btn-primary w-full mt-4">
                Add
              </button>
            </div>
          </div>
        </Box>
      </div>
      <div className="col-span-3">
        <Box className="w-full">
          <div className="grid grid-cols-3 lg:grid-cols-5 gap-2 p-2">
            <NavLink to="/dashboard/account">
              <Cube iconName="person" label={t('page.my-account')} />
            </NavLink>
            <NavLink to="/dashboard/changePassword">
              <Cube iconName="password" label={t('page.change-password')} />
            </NavLink>
            <Cube iconName="shopping_cart" label={t('navigation.shopping-cart')} />
            <NavLink to="/dashboard/requestHistory">
              <Cube iconName="history" label={t('page.request-history')} />
            </NavLink>
            <NavLink to="/dashboard/requestForm">
              <Cube iconName="assignment" label={t('page.send-request')} />
            </NavLink>
            <Cube iconName="sms" label={t('page.message')} />
          </div>
        </Box>
      </div>
    </DashboardLayout>
  )
}
