import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isSignedIn: window.localStorage.getItem('sessionId') !== null,
    sessionId:  window.localStorage.getItem('sessionId')
  },
  reducers: {
    signIn: (state, action) => {
      state.isSignedIn = true;
      state.sessionId = action.payload;
      window.localStorage.setItem('sessionId', action.payload);
    },
    signOut: (state) => {
      state.isSignedIn = false;
      state.sessionId = null;
      window.localStorage.removeItem('sessionId');
    },
  },
});

export const { signIn, signOut } = userSlice.actions;

export default userSlice.reducer;
