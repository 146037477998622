import React from "react";
import { NavLink } from "react-router-dom";
import { Box } from "./layouts/Box"
import { InputTireSize } from "./InputTireSize";
import { SelectCarMake } from "./SelectCarMake";
import { SelectCarModel } from "./SelectCarModel";
import { SelectCategory } from "./SelectCategory";
import { useTranslation } from 'react-i18next';
import { Cube } from "./Cube";

const targetSiteOption = [
  {
    categoryGroupId: 1,
    name: 'Yahoo Auction',
    query: 'p',
    endpoint: 'https://auctions.yahoo.co.jp/search/search',
    logoUrl: 'https://s.yimg.jp/c/logo/f/2.1/a/auctions_r_34_2x.png',
    others: [
      { key: 'fixed', value: () => '1' },
      { key: 'is_postage_mode', value: () => '1' },
      { key: 'dest_pref_code', value: () => '13' },
      { key: 'exflg', value: () => '1' },
      { key: 'b', value: () => '1' },
      { key: 'n', value: () => '50' },
      { key: 'auccat', value: c => c || "" },
    ]
  },
  {
    categoryGroupId: 2,
    name: 'Mercari',
    query: 'keyword',
    endpoint: 'https://jp.mercari.com/search',
    logoUrl: 'https://about.mercari.com/sample/index/serv-mercari.svg',
    others: [
      { key: 'category_id', value: c => c || "" },
    ]
  }
]
const searchTypes = [
  {
    name: 'searchBox.search-with-category',
    icon: 'list',
    href: '/search/category'
  },
  {
    name: 'searchBox.search-with-brand',
    icon: 'shoppingmode',
    href: '/search/brand'
  },
  {
    name: 'searchBox.search-with-tire',
    icon: 'straighten',
    href: '/search/tire'
  },
]
export const SearchBox = () => {
  const [ targetSite, setTargetSite ] = React.useState(0);

  const [ selectedMake, setSelectedMake ] = React.useState({});
  const [ make, setMake ] = React.useState('');
  const [ model, setModel ] = React.useState('');
  const [ parts, setParts ] = React.useState('');
  const [ tireSize, setTireSize ] = React.useState('');
  const [ selectedCategoryId, setSelectedCategoryId ] = React.useState();
  const { t } = useTranslation();

  const resetSearch = () => {
    setSelectedMake({});
    setMake('');
    setModel('');
    setParts('');
    setTireSize('');
    setSelectedCategoryId('');
  }

  React.useEffect(() => {
    setModel('');
  }, [ selectedMake ])

  return (
    <Box className="lg:p-4 p-2">
      <div role="tablist" className="tabs tabs-lifted lg:grid hidden">
        {
          targetSiteOption.map((site, i) => (
            <a key={i} role="tab" className={"tab h-10 !p-2 " + (targetSite === i ? 'tab-active': '')} onClick={setTargetSite.bind(null, i)}>
              <img src={site.logoUrl} className="h-full" />
            </a>
          ))
        }
      </div>
      <div className="lg:grid hidden grid-cols-3 gap-2 my-2 items-end">
        <div>
          <div className="form-control">
            <div className="label">
              <span className="label-text">{t('searchBox.make')}</span>
            </div>
            <SelectCarMake value={selectedMake ? selectedMake.id : ''} onChange={(selectedMake) => { setSelectedMake(selectedMake || {}); setMake(selectedMake ? selectedMake.name_ja : ""); }} />
          </div>
        </div>
        <div>
          <div className="form-control">
            <div className="label">
              <span className="label-text">{t('searchBox.model')}</span>
            </div>
            <SelectCarModel carMakeId={selectedMake.id ?? undefined} value={model} onChange={e => setModel(e.target.value)} />
          </div>
        </div>
        <div>
          <div className="form-control">
            <div className="label">
              <span className="label-text">{t('searchBox.tire-size')}</span>
            </div>
            <InputTireSize className="w-full" value={tireSize} onChange={setTireSize} />
          </div>
        </div>
        <div className="lg:col-span-3">
          <div className="form-control">
            <div className="label">
              <span className="label-text">{t('searchBox.category')}</span>
            </div>
            <SelectCategory categoryGroupId={targetSiteOption[targetSite].categoryGroupId} value={selectedCategoryId} onChange={setSelectedCategoryId} />
          </div>
        </div>
      </div>
      <div className="lg:flex hidden justify-center gap-4 mt-4">
        <form action={targetSiteOption[targetSite].endpoint} method="get" target="_blank" className="w-full">
          <div className="join w-full">
            <input
              type="text"
              className="join-item input input-bordered focus:outline-none w-full"
              name={targetSiteOption[targetSite].query}
              value={[make, model, parts, tireSize].filter(v => v !== '').join(' ')}
              placeholder={t('searchBox.placeholder', { siteName: targetSiteOption[targetSite].name })}
              readOnly
            />
            {
              targetSiteOption[targetSite].others.map((other, i) => (
                <input key={i} type="hidden" name={other.key} value={other.value(selectedCategoryId)} />
              ))
            }
            <button className="join-item btn btn-primary" type="submit">
              <span className="material-symbols-outlined">
                search
              </span>
            </button>
          </div>
        </form>
        <button className="btn btn-outline btn-error" onClick={resetSearch}>
          <span className="material-symbols-outlined">
            restart_alt
          </span>
          {t('searchBox.reset')}
        </button>
      </div>

      <div className="grid grid-cols-3 gap-2 lg:hidden">
        {
          searchTypes.map(
            (searchType, i) => (
              <NavLink key={i} to={searchType.href}>
                <Cube
                  iconName={searchType.icon}
                  label={t(searchType.name)}
                />
              </NavLink>
            )
          )
        }
      </div>
    </Box>
  )
}
