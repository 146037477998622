import PropTypes from 'prop-types';

export const SidemenuTitle = (props) => {
  const {
    className = "",
    ...rest
  } = props;
  return <h2 className={"text-base font-bold mb-4 " + className} {...rest} />
}
SidemenuTitle.propTypes = {
  className: PropTypes.string
}
