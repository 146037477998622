import React from "react"
import { Box } from "../../components/layouts/Box"
import axios from "../../lib/axios"
import { useDispatch } from "react-redux"
import { addInfoToast, addErrorToast } from "../../stores/features/toast/toastSlice"
import { DashboardLayout } from "../../components/layouts/DashboardLayout";
import { InputPassword } from "../../components/InputPassword"

export const ChangePasswordPage = () => {
  const dispatch = useDispatch();
  const [ currentPassword, setCurrentPassword ] = React.useState('');
  const [ newPassword, setNewPassword ] = React.useState('');
  const [ newPassword2, setNewPassword2 ] = React.useState('');
  const [ errorMessages, setErrorMessages ] = React.useState([]);
  const [ submitDisable, setSubmitDisable ] = React.useState(true);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/v1/changePassword', {
        currentPassword,
        newPassword
      })
      dispatch(addInfoToast('Successful changing password'))

      setCurrentPassword('');
      setNewPassword('');
      setNewPassword2('');
      setErrorMessages([]);
      setSubmitDisable(true);
    } catch(error) {
      error.response.data.errorMessages.forEach(
        err => dispatch(addErrorToast(err))
      )
    }
  }

  React.useEffect(() => {
    if (currentPassword === '' || newPassword === '' || newPassword2 === '') {
      setSubmitDisable(true);
      return;
    }
    if (newPassword !== newPassword2) {
      setErrorMessages([
        'new password is not matching'
      ]);
      setSubmitDisable(true);
      return;
    }
    if (currentPassword === newPassword) {
      setErrorMessages([
        'current password and new password are same'
      ]);
      setSubmitDisable(true);
      return;
    }
    setErrorMessages([]);
    setSubmitDisable(false);
  }, [
    newPassword,
    newPassword2,
    currentPassword
  ])

  return (
    <DashboardLayout pageTitle="Change password">
      <div className="col-span-3">
        <Box className="lg:p-12 p-4">
          <div className="w-full max-w-sm mx-auto">
            <form onSubmit={onSubmit}>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">Current Password</span>
                </div>
                <InputPassword
                  value={currentPassword}
                  onChange={e => setCurrentPassword(e.target.value)}
                  autoComplete="current-password"
                />
              </label>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">New Password</span>
                </div>
                <InputPassword
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                  autoComplete="new-password"
                />
                <div className="label">
                  <span className="label-text">Use 8 or more characters with a mix of upper, lower, numbers and symbols</span>
                </div>
              </label>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">Again</span>
                </div>
                <InputPassword
                  value={newPassword2}
                  onChange={e => setNewPassword2(e.target.value)}
                  autoComplete="new-password"
                />
                <div className="label">
                  <span className="label-text">Type again same password</span>
                </div>
              </label>
              <button className="btn btn-primary w-full my-4" type="submit" disabled={submitDisable}>
                <span className="material-symbols-outlined">
                  password
                </span>
                Change Password
              </button>
              {errorMessages.map((err, i) => (
                  <div key={i} className="text-error">{err}</div>
              ))}
          </form>
        </div>
      </Box>
    </div>
  </DashboardLayout>
  )
}
