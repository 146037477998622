import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../fetchBaseQuery';

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery,
    tagTypes: [
        'User'
    ],
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/users';
                } else {
                    return '/users?' + new URLSearchParams(queries);
                }
            },
            providesTags: (result, error, arg) =>
            result
            ? [...result.map(({ id }) => ({ type: 'User', id })), 'User']
            : ['User'],
        }),
        countUsers: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/users/counts';
                } else {
                    return '/users/counts?' + new URLSearchParams(queries);
                }
            }
        }),
        getUser: builder.query({
            query: (id) => `/user/${id}`,
            providesTags: (result, error, arg) => [{type: 'User', id: result.id}]
        }),
        createUser: builder.mutation({
            query: (body) => ({
                url: `/user`,
                method: 'POST',
                body,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: ['User']
        }),
        updateUser: builder.mutation({
            query: ({ id, ...patch }) => ({
                url: `/user/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'User', id: arg.id}]
        }),
        deleteUser: builder.mutation({
            query: (id) => ({
                url: `/user/${id}`,
                method: 'DELETE'
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'User', id: arg.id}]
        }),
    }),
})

export const {
    useGetUsersQuery,
    useCountUsersQuery,
    useGetUserQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
} = userApi
