import { CommonLayout } from "../components/layouts/CommonLayout"
import { Container } from "../components/layouts/Container"
import { Box } from "../components/layouts/Box"
import { useTranslation } from 'react-i18next';
import { PageTitle } from "../components/styled/PageTitle"

export const AboutUsPage = () => {
  const { t } = useTranslation();

  return (
    <CommonLayout>
      <section className="bg-base-200">
        <Container>
          <PageTitle>
            {t('page.about-us')}
          </PageTitle>
          <Box className="p-4">
            <h2 className="text-2xl font-bold py-4">{t('about-us.company-profile')}</h2>
            <h3 className="text-lg font-bold py-1 border-b border-1 border-base-300">{t('about-us.company-name')}</h3>
            <div className="py-1">UNFOLDED CO., LTD. </div>
            <h3 className="text-lg font-bold py-1 border-b border-1 border-base-300">{t('about-us.ceo')}</h3>
            <div className="py-1">
              Daichi Fujimoto
            </div>
            <h3 className="text-lg font-bold py-1 border-b border-1 border-base-300">{t('about-us.established')}</h3>
            <div className="py-1">
              September 26, 2023
            </div>
            <h3 className="text-lg font-bold py-1 border-b border-1 border-base-300">{t('about-us.office')}</h3>
            <div className="py-1">
              Japan, 7F, T&J Building, 2-2-12 Tenjin, Chuo Ward, Fukuoka City, Fukuoka Prefecture
            </div>
            <h3 className="text-lg font-bold py-1 border-b border-1 border-base-300">{t('about-us.email')}</h3>
            <div className="py-1">
              info@unfolded.jp
            </div>
            <h3 className="text-lg font-bold py-1 border-b border-1 border-base-300">{t('about-us.our-bussiness')}</h3>
            <div className="py-1">
              <ul className="list-inside list-disc">
                <li>Operation of TSUNO MARKET (Mongolia-bound auto parts shopping proxy service and export business)</li>
                <li>Purchasing and reselling of used vehicle parts</li>
                <li>Exporting new and used tuning parts for vehicles</li>
              </ul>
            </div>
            <h3 className="text-lg font-bold py-1 border-b border-1 border-base-300">{t('about-us.antique-dealer-permit-number')}</h3>
            <div className="py-1">
              No. 904022310021
            </div>
          </Box>
        </Container>
      </section>
    </CommonLayout>
  )
}
