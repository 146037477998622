import React from "react"
import { useDispatch } from "react-redux";
import { addInfoToast } from "../../stores/features/toast/toastSlice";
import { DashboardLayout } from "../../components/layouts/DashboardLayout";
import { Box } from "../../components/layouts/Box"
import { useCreateOrderItemRequestedMutation } from "../../stores/services/orderItemRequested";
import { useCreateImageMutation } from "../../stores/services/image";
import { useCreateOrderMutation } from "../../stores/services/order";
import { convertBufferToBase64 } from "../../lib/utils";
import { useTranslation } from 'react-i18next';

export const RequestFormPage = () => {
  const dispatch = useDispatch();
  const [ itemName, setItemName ] = React.useState('');
  const [ itemPrice, setItemPrice ] = React.useState('');
  const [ itemPageUrl, setItemPageUrl ] = React.useState('');
  const [ itemImage, setItemImage ] = React.useState('');
  const [ remarks, setRemarks ] = React.useState('');
  const [ errorMessages, setErrorMessages ] = React.useState({});

  const [ createOrderItemRequested ] = useCreateOrderItemRequestedMutation();
  const [ createImage ] = useCreateImageMutation();
  const [ createOrder ] = useCreateOrderMutation();

  const ref = React.useRef(null);
  const { t } = useTranslation();

  const reset = () => {
    setItemName('');
    setItemPrice('')
    setItemPageUrl('');
    setItemImage('');
    setRemarks('');
    setErrorMessages([])
    ref.current.reset();
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    setErrorMessages(prev => ({
      ...prev,
      itemName: '',
      itemPrice: '',
      itemPageUrl: ''
    }))

    if (
      itemName === ''
    ) {
      setErrorMessages(prev => ({
        ...prev,
        itemName: t('error.required')
      }))
      return;
    }
    if (
      itemPrice === ''
    ) {
      setErrorMessages(prev => ({
        ...prev,
        itemPrice: t('error.required')
      }))
      return;
    }
    if (
      itemPageUrl === ''
    ) {
      setErrorMessages(prev => ({
        ...prev,
        itemPageUrl: t('error.required')
      }))
      return;
    } else if (
      /^(https:\/\/jp\.mercari\.com\/item\/.+|https:\/\/page\.auctions\.yahoo\.co\.jp\/jp\/auction\/.+)/
      .test(itemPageUrl) === false
    ) {
      setErrorMessages(prev => ({
        ...prev,
        itemPageUrl: t('error.wrong-url')
      }))
      return;
    }
    if (itemImage) {
      const buf = await itemImage.arrayBuffer();
      if (buf.byteLength > 3 * 1024 * 1024) {
        setErrorMessages(prev => ({
          ...prev,
          itemImage: t('error.up-to-3mb')
        }))
        return;
      }
    }

    if (!confirm(t('request.are-you-sure'))) return;

    let auctionSite, imageId;

    if (/^https:\/\/jp\.mercari\.com\/item/.test(itemPageUrl)) {
      auctionSite = 'MERCARI';
    }
    if (/^https:\/\/page\.auctions\.yahoo\.co\.jp\/jp\/auction/.test(itemPageUrl)) {
      auctionSite = 'YAHOO';
    }

    if (itemImage) {
      const buf = await itemImage.arrayBuffer();

      const { data: image } = await createImage({
        content: {
          type: "base64",
          content: convertBufferToBase64(buf)
        }
      })
      imageId = image.id;
    }

    const { data: order } = await createOrder();

    await createOrderItemRequested({
      order_id: order.id,
      auction_id: itemPageUrl.split('?')[0].split('/').pop(),
      auction_site: auctionSite,
      product_name: itemName,
      product_price: itemPrice,
      auction_page_image_id: imageId || null,
      remarks
    })

    dispatch(addInfoToast('Successful to request'))
    reset();
  }

  return (
    <DashboardLayout pageTitle={t('page.send-request')}>
      <div className="col-span-3">
        <Box className="lg:p-12 p-4">
          <div className="w-full max-w-sm mx-auto">
            <form onSubmit={onSubmit} ref={ref}>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">{t('request.item-name')}</span>
                </div>
                <input
                  type="text"
                  className="input input-bordered w-full focus:outline-none"
                  value={itemName}
                  onChange={e => setItemName(e.target.value)}
                  placeholder={t('request.item-name.placeholder')}
                  required
                />
                <div className="label">
                  <span className="label-text text-error">{errorMessages.itemName}</span>
                </div>
              </label>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">{t('request.item-price')}</span>
                </div>
                <input
                  type="text"
                  inputMode="numeric"
                  className="input input-bordered w-full focus:outline-none"
                  value={itemPrice}
                  onChange={e => setItemPrice(e.target.value.replace(/[^0-9]/g, ''))}
                  placeholder={t('request.item-price.placeholder')}
                  required
                />
                <div className="label">
                  <span className="label-text text-error">{errorMessages.itemPrice}</span>
                </div>
              </label>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">{t('request.item-page-url')}</span>
                </div>
                <input
                  type="url"
                  className="input input-bordered w-full focus:outline-none"
                  value={itemPageUrl}
                  onChange={e => setItemPageUrl(e.target.value)}
                  placeholder={t('request.item-page-url.placeholder')}
                  required
                />
                <div className="label">
                  <span className="label-text text-error">{errorMessages.itemPageUrl}</span>
                </div>
              </label>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">{t('request.item-image')}</span>
                </div>
                <input
                  type="file"
                  className="file-input file-input-bordered w-full focus:outline-none"
                  onChange={e => setItemImage(e.target.files[0])}
                  accept="image/*"
                />
                <div className="label">
                  <span className="label-text text-error">{errorMessages.itemImage}</span>
                </div>
              </label>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">{t('request.item-remarks')}</span>
                </div>
                <textarea
                  className="textarea textarea-bordered w-full focus:outline-none"
                  value={remarks}
                  onChange={e => setRemarks(e.target.value)}
                  placeholder={t('request.item-remarks.placeholder')}
                />
              </label>
              <button className="btn btn-primary w-full my-4" type="submit">
                {t('request.request')}
              </button>
              <ul>
                <li>* {t('request.note-1')}</li>
              </ul>
            </form>
        </div>
      </Box>
    </div>
  </DashboardLayout>
  )
}
