import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../fetchBaseQuery';

export const categoryGroupApi = createApi({
    reducerPath: 'categoryGroupApi',
    baseQuery,
    tagTypes: [
        'CategoryGroup'
    ],
    endpoints: (builder) => ({
        getCategoryGroups: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/categoryGroups';
                } else {
                    return '/categoryGroups?' + new URLSearchParams(queries);
                }
            },
            providesTags: (result, error, arg) =>
            result
            ? [...result.map(({ id }) => ({ type: 'CategoryGroup', id })), 'CategoryGroup']
            : ['CategoryGroup'],
        }),
        countCategoryGroups: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/categoryGroups/counts';
                } else {
                    return '/categoryGroups/counts?' + new URLSearchParams(queries);
                }
            }
        }),
        getCategoryGroup: builder.query({
            query: (id) => `/categoryGroup/${id}`,
            providesTags: (result, error, arg) => [{type: 'CategoryGroup', id: result.id}]
        }),
        createCategoryGroup: builder.mutation({
            query: (body) => ({
                url: `/categoryGroup`,
                method: 'POST',
                body,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: ['CategoryGroup']
        }),
        updateCategoryGroup: builder.mutation({
            query: ({ id, ...patch }) => ({
                url: `/categoryGroup/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'CategoryGroup', id: arg.id}]
        }),
        deleteCategoryGroup: builder.mutation({
            query: (id) => ({
                url: `/categoryGroup/${id}`,
                method: 'DELETE'
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'CategoryGroup', id: arg.id}]
        }),
    }),
})

export const {
    useGetCategoryGroupsQuery,
    useCountCategoryGroupsQuery,
    useGetCategoryGroupQuery,
    useCreateCategoryGroupMutation,
    useUpdateCategoryGroupMutation,
    useDeleteCategoryGroupMutation,
} = categoryGroupApi
