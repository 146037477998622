import React from 'react';
import PropTypes from 'prop-types'
import { useGetImageQuery } from '../stores/services/image';
import { convertBase64ToBlob } from '../lib/utils';

export const Image = (props) => {
  const {
    srcId,
    ...rest
  } = props;

  const {
    data = null,
    error,
    isLoading
  } = useGetImageQuery(srcId);

  const [ url, setUrl ] = React.useState('');

  React.useEffect(() => {
    if (data === null) return;
    const _url = URL.createObjectURL(
      convertBase64ToBlob(data.content)
    )
    setUrl(_url);

    return () => {
      URL.revokeObjectURL(_url);
    }
  }, [data])

  if (isLoading) {
    return <div className="skeleton h-32 w-32"></div>;
  } else {
    return <img src={url} {...rest} />
  }
}
Image.propTypes = {
  srcId: PropTypes.number
}
