import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./Toast.css";

const Toast = (props) => {
  const { id, message, color } = props;

  const ref = React.useRef();

  React.useEffect(() => {
    if (id === null) return;

    const e = document.createElement("div");
    switch (color) {
      case "info":
        e.className = "c-alert c-alert-info";
        break;
      case "success":
        e.className = "c-alert c-alert-success";
        break;
      case "error":
        e.className = "c-alert c-alert-error";
        break;
      default:
        e.className = "c-alert c-alert-info";
    }
    const child = document.createElement("span");
    child.textContent = message;
    e.appendChild(child);
    ref.current.appendChild(e);

    setTimeout(() => {
      ref.current.removeChild(e);
    }, 5000);
  }, [id, message, color]);

  return <div className="custom-toast z-10" ref={ref} />
};
Toast.propTypes = {
  id: PropTypes.number,
  message: PropTypes.string,
  color: PropTypes.string,
};

export default connect((state) => ({ ...state.toast }))(Toast);
