import { createSlice, createSelector } from "@reduxjs/toolkit";
import i18n from '../../../i18n';

export const Languages = [
  { lang: "ja", name: "日本語" },
  { lang: "en", name: "English" },
  { lang: "mn", name: "монгол" },
]
export const i18nSlice = createSlice({
  name: "i18n",
  initialState: {
    lang: window.localStorage.getItem('lang') || "mn"
  },
  reducers: {
    switchLanguage: (state, action) => {
      i18n.changeLanguage(action.payload);
      state.lang = action.payload;
      window.localStorage.setItem('lang', state.lang);
      window.document.children[0].attributes.lang.value = state.lang;
    },
  },
  selectors: {
    currentLanguageName: state => {
      return Languages.find(l => l.lang === state.lang);
    }
  }
});
console.log(i18nSlice)
window.document.children[0].attributes.lang.value = window.localStorage.getItem('lang') || "mn";

export const { switchLanguage } = i18nSlice.actions;
export const getSelectedLanguageName = createSelector(
  state => state.i18n,
  state => Languages.find(l => l.lang === state.lang).name
)

export default i18nSlice.reducer;
