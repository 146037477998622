import { CommonLayout } from "../components/layouts/CommonLayout"
import { Container } from "../components/layouts/Container"
import { Box } from "../components/layouts/Box"
import { useTranslation } from 'react-i18next';
import { PageTitle } from "../components/styled/PageTitle"

export const PrivacyAndCookiePolicyPage = () => {
  const { t } = useTranslation();

  return (
    <CommonLayout>
      <section className="bg-base-200">
        <Container>
          <PageTitle>
            {t('page.privacy-and-cookie-policy')}
          </PageTitle>
          <Box className="p-4">
            <h2 className="text-lg font-bold">Privacy & Cookies Policy</h2>
            <p className="py-2">
              Please read our Privacy Policy carefully
            </p>
            <h2 className="text-lg font-bold">Our commitment to the protection of personal information</h2>
            <p className="py-2">Our commitment to the protection of personal information</p>
            <p className="py-2">Your privacy is important to us. To better protect your privacy, we provide this page explaining our practices regarding the treatment of your information and the choices you can make about how your information is collected and used.</p>
            <p className="py-2">Unfolded, Co., Ltd., (Our Website: Tsuno Market) here in after "our company" or “we” understands the importance of your provided personal information (including your name, address, phone number, and any other information that could identify you), and will observe the "Act concerning Protection of Personal Information" along with this privacy policy to take the necessary steps to properly manage your information, and strive to protect it as well as continuously reviewing and improving our efforts.</p>
            <h2 className="text-lg font-bold">What information do we collect?</h2>
            <p className="py-2">A Member refers to an individual or legal entity who has consented to Tsuno Market's terms of use, has requested and consented to the use of the service offered by the company, and has been accepted by Tsuno Market as a Member of the site.</p>
            <p className="py-2">When placing an order or signing up on our site, if necessary, you may be asked to enter your name or email address. You can, however, visit our website anonymously.</p>
            <p className="py-2">When purchasing (request or order) or shipping your items, you will also be asked to provide a billing and shipping address, both of which must be associated with a contact phone number.
              <ul className="list-decimal list-inside">
                <li>
                  Members Information: Upon the online signup process, we collect your contact information including e-mail address, telephone/fax/mobile number, etc.
                </li>
                <li>
                  Registration information: At the time of your registration or attempt of registration with us, we collect your contact information including the details of the Products you are looking for. We may collect the aforesaid information, even if you did not complete the online transactions with us, and use such information under our Privacy Policy.
                </li>
                <li>
                  Billing & Payment Information: If you purchase or attempt to purchase any product or any other service from Unfolded(TsunoMarket) through our website, we or our trading or consulting partners may collect additional information including billing and shipping information, payment information, delivery options etc. We may collect the aforesaid information, even if you did not complete the online transactions with us, and use such information under our Privacy Policy.
                </li>
                <li>Statistical Information: In addition, we gather aggregate statistical information about our Site and Users, such as IP addresses, browser software, operating system, pages viewed, number of sessions and unique visitors, etc. We may collect the aforesaid information, even if you did not complete the online transactions with us, and use such information according to our Privacy Policy.</li>
                <li>
                  Communication Services Information: At the time of your use or attempted use of our Communication Services (the services may contain e-mail services, auto-alert, newsletter, tell your friend, feedback and/or any other message or communication facilities designed to enable you to communicate with us or others) information such as your name, address, phone/fax number, email address and other personal information as well as information about your business shall be provided. We may collect the aforesaid information, even if you did not complete the online transactions with us and use such information under our Privacy Policy.
                </li>
                <li>
                  Collected Information: Member’s Information, Registration Information, Purchase Request Information, Billing & Payment Information, Statistical Information and any other information we may collect from you through our Website or any other means shall collectively be referred to as "Collected Information". Such information shall include all information that you have typed in our online system, even if you did not complete the transactions on the system.
                </li>
              </ul>
            </p>
            <h2 className="text-lg font-bold">How do we use your information?</h2>
            <p className="py-2">
              We use the information you provide when placing an order only to fulfill that order. We do not share this information with third parties except to the extent necessary to fulfill this order. We use the information you provide about someone else when you place an order only to ship the product and confirm delivery. We do not share this information with third parties except to the extent necessary to fulfill this order. We use return email addresses to reply to emails we receive. These addresses are not used for other purposes and they are not shared with third parties. Finally, we do not use or share personally identifiable information provided to us for purposes other than those described above without also providing you with an opportunity to opt out or otherwise prohibit such use. When providing information to third parties, we anonymize user information to ensure that specific individuals cannot be identified.
            </p>
            <h2 className="text-lg font-bold">Commitment to data security</h2>
            <p className="py-2">
              To prevent unauthorized access, maintain data accuracy and ensure the correct use of information, we have implemented physical, electronic and management procedures to safeguard and secure the information we collect online.
            </p>
            <h2 className="text-lg font-bold">
              How you can access or correct your information
            </h2>
            <p className="py-2">
              You can access all your personally identifiable information that we collect online through your user interface.
            </p>
            <h2 className="text-lg font-bold">Disclosure of Information</h2>
            <p className="py-2">
              We reserve the right to disclose your Collected Information to relevant authorities where we have reason to believe that such disclosure is necessary to identify, contact or bring legal action against someone who may be infringing or threatening to infringe, or who may otherwise be causing injury to or interference with, the title, rights, interests or property of Unfolded(TsunoMarket), our Users, customers, partners, other web site users or anyone else who could be harmed by such activities.
            </p>
            <p className="py-2">
              We also reserve the right to disclose Collected Information in response to a subpoena, a rit of summons or other judicial order or when we reasonably believe that such disclosure is required by law, regulation or administrative order of any court, Governmental or regulatory authority.
            </p>
            <p className="py-2">
              If we have a reason to believe that a particular user is in breach of the terms and conditions or any agreements with us, then we reserve the right to make public or otherwise disclose such user's Collect Information in order to pursue our claim or prevent further injury to Unfolded(Tsuno Market) or others.
            </p>
            <h2 className="text-lg font-bold">Changes to Privacy policy</h2>
            <p className="py-2">
              This privacy policy may be modified at any time without notice, so we invite you to consult it regularly.
            </p>
            <h2 className="text-lg font-bold">Privacy of Third Party</h2>
            <p className="py-2">
              We cannot be held liable for the privacy/confidentiality practices employed by third party to whom we direct our visitors. The privacy policy of such parties may differ from ours, and we may not have any control over the information that you submit to such third parties or co-branded sites. We strongly recommend that you read the confidentiality declaration on each website you visit.
            </p>
            <h2 className="text-lg font-bold">Security Measures</h2>
            <p className="py-2">
              No data transmission over the Internet or any wireless network can be guaranteed to be perfectly secured. As a result, while we try to protect your information, no web site or company, including ourselves, can absolutely ensure or guarantee the security of any information you transmit to us and you do so at your own risk.
            </p>
            <h2 className="text-lg font-bold">Your Feedback</h2>
            <p className="py-2">
              We welcome your continuous input regarding our Privacy Policy or our services provided to you. You may send us your comments and responses to our email.
            </p>
            <h2 className="text-lg font-bold">Way to contact us</h2>
            <p className="py-2">
              If you have any other questions or concerns about this privacy policy, do not hesitate to contact us.
            </p>
            <h2 className="text-lg font-bold">Cookie</h2>
            <p className="py-2">
              We use cookies to enhance your browsing experience, serve personalized content, and analyze our traffic. Using our website means you are agreeing to the use of cookies.
              <ul className="list-decimal list-inside">
                <li>
                  What are cookies?
                  <p className="py-2">
                    Cookies are data files stored on your computer or mobile devices when you visit a website. These cookies recognize you the next time you visit the same website. And with the stored cookies, website owners can provide you with better information and an enhanced experience.
                  </p>
                </li>
                <li>
                  How do we use cookies?
                  <p className="py-2">
                    We use cookies to distinguish you from other users of our website and to provide a browsing experience that is unique to you. The use of cookies helps us improve our site usage and performance and helps us better understand your needs and your preferences when visiting our website. We use a third-party service provider for the analysis of our site’s usage and performance. The collected information will be used for the purpose of analyzing site usage and performance and providing other services and advertisement delivery.
                  </p>
                </li>
                <li>
                  How do you block cookies?
                  <p className="py-2">
                    Most browsers allow you to refuse cookies, and you are always free to decline our cookies. You may block our cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies.
                    If you block our use of cookies, the functionality of this website can be restricted and may affect your user experience.
                  </p>
                </li>
                <li>
                  Provided personal information to third parties
                  <p className="py-2">
                    We provide personal information such as Cookies, IP addresses, etc., to third parties. The provided personal information to third parties is stored in other countries and is used for the following purposes.
                  </p>
                </li>
                <li>
                  Usage of the Provided Personal Information
                  <ul className="list-disc list-inside pl-2">
                    <li>
                      Site Usage Analysis
                    </li>
                    <li>
                      Advertisement Delivery
                    </li>
                    <li>
                      Sales Activities
                    </li>
                  </ul>
                </li>
                <li>
                  Provisions for Third Parties
                  <ul className="list-disc list-inside pl-2">
                    <li>
                      Google
                    </li>
                    <li>
                      Bing
                    </li>
                    <li>
                      Meta (Facebook, Instagram)
                    </li>
                    <li>
                      X(Twitter)
                    </li>
                    <li>
                      TikTok
                    </li>
                    <li>
                      Outsourcing Companies
                    </li>
                  </ul>
                </li>
              </ul>
            </p>
            <h2 className="text-lg font-bold">Payments</h2>
            <p className="py-2">
              We provide paid products and/or services, and we use third-party payment collecting services for payment processing (e.g. payment processors).
            </p>
            <p className="py-2">
              We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information. When you purchase our Services, we will also collect transaction information, which may include billing and mailing address, and other payment-related information.
            </p>
            <p className="py-2">
              The payment processors we work with are:
              Paypal
            </p>
            <p className="py-2 whitespace-wrap">
              Their Privacy Policy can be viewed at <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full" className="link link-primary" target="_blank">https://www.paypal.com/webapps/mpp/ua/privacy-full</a>
            </p>
            <h2 className="text-lg font-bold">Storage</h2>
            <p className="py-2">
              We use third-party web hosting and storage services to securely store our website and databases, including your Personal Data.
            </p>
            <h2 className="text-lg font-bold">Contact Information</h2>
            <p className="py-2">
              When you need to delete your information, please contact us by email at support@tsuno-market.jp
            </p>
          </Box>
        </Container>
      </section>
    </CommonLayout>
  )
}
