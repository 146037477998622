import { createSlice } from "@reduxjs/toolkit";

let count = 0;
export const toastSlice = createSlice({
  name: "toast",
  initialState: {
    id: null,
    message: null,
    color: null,
  },
  reducers: {
    addInfoToast: (state, action) => {
      state.id = ++count;
      state.message = action.payload;
      state.color = "info";
    },
    addErrorToast: (state, action) => {
      state.id = ++count;
      state.message = action.payload;
      state.color = "error";
    },
  },
});

export const { addInfoToast, addErrorToast } = toastSlice.actions;

export default toastSlice.reducer;
