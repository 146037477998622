import { StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import {
  RouterProvider,
} from "react-router-dom";
import { router } from './router';
import { Provider } from "react-redux";
import store from "./stores";
import './index.css'
import Toast from './components/Toast';
import './i18n'

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <Provider store={store}>
      <Suspense fallback={<div>Loading...</div>}>
        <RouterProvider router={router} />
        <Toast />
      </Suspense>
    </Provider>
  </StrictMode>,
)
// vim:sw=2:ai
