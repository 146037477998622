import React from "react";
import { CommonLayout } from "../components/layouts/CommonLayout"
import { Container } from "../components/layouts/Container"
import { PageTitle } from "../components/styled/PageTitle"
import { InputUserName } from "../components/InputUserName"
import { Box } from "../components/layouts/Box"
import { useTranslation } from "react-i18next"
import axios from "../lib/axios";
import { useDispatch } from "react-redux";
import { addErrorToast } from "../stores/features/toast/toastSlice";

export const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [ userName, setUserName ] = React.useState('');
  const [ successMessage, setSuccessMessage ] = React.useState([]);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/v1/forgotPassword', {
        userName,
      });
      setSuccessMessage([
        t('resetPassword.after-sending-email')
      ])
      setUserName('');
    } catch(error) {
      error.response.data.errorMessages.forEach(
        err => dispatch(addErrorToast(err))
      )
      setSuccessMessage([]);
    }
  }

  return (
    <CommonLayout>
      <section className="bg-base-200">
        <Container>
          <PageTitle>
            {t('page.forgot-password')}
          </PageTitle>
          <Box className="lg:p-12 p-4">
            <div className="w-full max-w-sm mx-auto">
              <form onSubmit={onSubmit}>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text">{t('common.email-address')}</span>
                  </div>
                  <InputUserName
                    value={userName}
                    onChange={e => setUserName(e.target.value)}
                    autoComplete="username"
                  />
                </label>
                <button className="btn btn-primary w-full my-4" type="submit">
                  <span className="material-symbols-outlined">
                    send
                  </span>
                  {t('common.submit')}
                </button>
                {successMessage.map((msg, i) => (
                  <div key={i} className="text-success">{msg}</div>
                ))}
              </form>
            </div>
          </Box>
        </Container>
      </section>
    </CommonLayout>
  )
}
