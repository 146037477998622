import { CommonLayout } from "../components/layouts/CommonLayout"
import { Container } from "../components/layouts/Container"
import { Box } from "../components/layouts/Box"
import { useTranslation } from 'react-i18next';
import { PageTitle } from "../components/styled/PageTitle"

export const TermsOfUsePage = () => {
  const { t } = useTranslation();

  return (
    <CommonLayout>
      <section className="bg-base-200">
        <Container>
          <PageTitle>
            {t('page.terms-of-use')}
          </PageTitle>
          <Box className="p-4">
            <h2 className="text-lg font-bold">Terms and Conditions</h2>
            <p className="py-2">
              Please read our TERMS OF USE（TOU）carefully
            </p>
            <h2 className="text-lg font-bold">Tsuno Market Service Details</h2>
            <p className="py-2">
              Unfolded., ltd (Tsuno Market -hereinafter referred to as “the Service”) is a service that allows users to bid on and purchase products from third-party websites, primarily based on requests from members, and then ship the purchased products to members via a shipping company of their choice. The Service is not available to users who wish to have their items shipped to addresses within Japan. This service should only be used when the user wishes to ship goods outside of Japan (only to Mongolia as of 2024).
            </p>
            <h2 className="text-lg font-bold">Definition of a Member</h2>
            <p className="py-2">
              A Member refers to an individual or legal entity who has consented to Tsuno Market's terms of use, has requested and consented to the use of the service offered by the company, and has been accepted by Tsuno Market as a Member of the site.
            </p>
            <h2 className="text-lg font-bold">Limitation of Liability</h2>
            <p className="py-2">
              As explained in the "Service Description" section, the service involves Tsuno Market's purchase of products specified by a Member by the Member's instructions.
            </p>
            <p className="py-2">
              Therefore, Tsuno Market is only responsible for the purchase and shipping of products submitted by a Member. And therefore is not responsible for any claims regarding the description of the products received, any defects, or other claims.
            </p>
            <p className="py-2">
              Tsuno Market is not affiliated with any auction site, their affiliates, or any other company mentioned on this site. Tsuno Market is not an auctioneer and we have no affiliation with any sellers. We do not make any suggestions about the items you can find via this site. The translation tools offered by our site are only present for the convenience of the user.
            </p>
            <p className="py-2">
              Please, make sure the item meets your expectations before doing any bidding by yourself carefully.
            </p>
            <h2 className="text-lg font-bold">Alterations to the Terms of Service</h2>
            <p className="py-2">
              Unfolded., ltd (Tsuno Market) reserves the right to modify these terms of service without prior notice provided and without prior acceptance of these terms of service by its user base. Use of the Tsuno Market website by the Member means the acceptance of these terms and any modification of these terms.
            </p>
            <p className="py-2">
              In the event of significant changes to our privacy policy, we will announce these changes on our homepage and social networks.
            </p>
            <h2 className="text-lg font-bold">
              Products for which the use of Tsuno Market is prohibited
            </h2>
            <p className="py-2">
              A Member must not use Tsuno Market to import the following types of products:
            </p>
            <ul className="list-decimal list-inside">
              <li>
                Items size or weight more than exceeds the limits allowed by the delivery company (In cace of Japan Post).
              </li>
              <li>
                Stimulants, cannabis, narcotics or other illicit substances are prohibited.
              </li>
              <li>
                Food or drink, or any other item that can easily deteriorate or spoil.
              </li>
              <li>
                Firearms, swords, weapons, ammunition, powders, explosives, poisons and dangerous substances.
              </li>
              <li>
                Medical supplies and equipment.
              </li>
              <li>
                Child pornography, adult videos, or other obscene material.
              </li>
              <li>
                Items containing a magnet (these can be accepted only if shipped by FedEx)
              </li>
              <li>
                Items containing dangerous substances such as gasoline oil or gas.
              </li>
              <li>
                Any item containing a lithium battery.
              </li>
              <li>
                Items whose transport, export, or import are prohibited or restricted by the laws and regulations of either the exporting / importing country, including transit countries, national or local governments.
              </li>
              <li>
                Items not authorized for transport by the carrier (Japan Post, FedEx, etc.) Any object containing or which may contain gas or compressed air (such as a tire, spray paint can, or gas cylinder)
              </li>
              <li>Dead or living animals or plants.</li>
              <li>Importing products containing alcohol may be limited according to your local legislation.</li>
            </ul>
            <p className="py-2">
              Please inquire about this before bidding on this category of items.
            </p>
            <p className="py-2">
              Other products which Tsuno Market considers improper.
            </p>
            <h2 className="text-lg font-bold">Product deliveries.</h2>
            <p className="py-2">
              After the delivery of items to our premises, Tsuno Market do not verify their contents. Tsuno Market can remove or add packaging if deemed necessary.
            </p>
            <p className="py-2">
              Tsuno Market has no obligation to inspect the product. Tsuno Market can not tamper with an order on the Member request. Tsuno Market can not guarantee the quality of the product, the presence or absence of defects, the authenticity of the product, or that the said product will not violate the laws or ordinances of any countries while the product is en route to it&#39;s destination.
            </p>
            <p className="py-2">
              If we detect an element that violates or is likely to violate the laws and regulations in force, Tsuno Market may take measures to report such a situation to the relevant authorities.
            </p>
            <p className="py-2">
              If a Member has been the victim of fraud, Tsuno Market does not assume any responsibility.
            </p>
            <p className="py-2">
              After completing the verification process, Tsuno Market performs procedures for shipping or exporting products overseas on behalf of the Member. Tsuno Market may, at its discretion, determine the method of delivery, international delivery charges, and other expenses such as postage and handling charges, and/or the costs necessary for the delivery of products according to the weight and size of the goods.
            </p>
            <p className="py-2">
              Tsuno Market may, at its discretion, decide on a shipping date for the products if the Member cannot specify a shipping date.
            </p>
            <p className="py-2">
              The Member acknowledges that in case of dispute or fraudulent action from the seller, or if the items purchased on behalf of the member were never received by Tsuno Market, no refund will be possible in the event that no recourse to the sales platform, seller or carrier is possible.
            </p>
            <p className="py-2">
              The Member acknowledges that if the product clearly differs from the details that the seller has given in the product description, Tsuno Market will not be able to raise the issue with the seller, neither proceed the return of the product for a refund or replacement.
            </p>
            <h2 className="text-lg font-bold">International Shipping and Refunds policy</h2>
            <p className="py-2">
              Tsuno Market does not accept returns and does not make refunds for purchased items.
            </p>
            <h2 className="text-lg font-bold">CANCELLATION OF MEMBERSHIP</h2>
            <p className="py-2">
              <ul className="list-decimal list-inside">
                <li>Tsuno Market may suspend the use of Services by any Client, change his/her ID and password, and terminate his/her status as a Client, if any Client:
                  <ul className="list-disc list-inside pl-2">
                    <li>
                      has given false information at the time of membership registration or gives such false information when using the Services,
                    </li>
                    <li>
                      violates laws and regulations or the TOS,
                    </li>
                    <li>
                      participates in dishonest conduct in using the Services,
                    </li>
                    <li>
                      makes mistakes inputting their password over the permitted number of times or does other actions that may cause TsunoMarket to take necessary measures to ensure security of the respective Client.
                    </li>
                  </ul>
                </li>
                <li>TsunoMarket disclaims any liability whatsoever for any disadvantage or damage incurred to any respective Client whose membership is canceled or suspended.</li>
              </ul>
            </p>
            <h2 className="text-lg font-bold">CHANGES OF SERVICES</h2>
            <p className="py-2">
              TsunoMarket may, at its own discretion, with or without prior notice to Clients, change or delete the whole or parts of the Service at any time.
            </p>
            <h2 className="text-lg font-bold">CHANGES OF TOS</h2>
            <p className="py-2">
              <ul className="list-decimal list-inside">
                <li>TsunoMarket may, at its own discretion, alter, change, amend or modify parts of the TOS from time to time without any prior notice. Such alteration, change, amendment and/or modification shall be effective upon posting of such on the Website.</li>
                <li>Upon any Client&#39;s use of Services after such alteration, change, amendment and/or modification, he/she is deemed to have agreed to terms and conditions of altered, changed, amended and or modified TOS.</li>
              </ul>
            </p>
            <h2 className="text-lg font-bold">Applicable Laws and Jurisdictions</h2>
            <p className="py-2">
              Japanese laws govern over the terms of service.
            </p>
            <p className="py-2">
              If a dispute arises in connection with the terms of service or separate conditions, Fukuoka District Court is the agreed and exclusive jurisdiction of the first hearing.
            </p>
            <h2 className="text-lg font-bold">DISCONTINUATION AND SUSPENSION OF SERVICES</h2>
            <p className="py-2">
              <ul className="list-decimal list-inside">
                <li>Tsuno Market may, without prior notice to Clients, temporarily discontinue Services in case of:
                  <ul className="list-disc list-inside pl-2">
                    <li>
                      regular or urgent maintenance of the system and relevant equipment necessary for providing the Services,
                    </li>
                    <li>
                      interruption, including but not limited to, in communication lines and/or power supply deployed by TsunoMarket,
                    </li>
                    <li>
                      actual or threatened earthquake, typhoon, flood, tsunami or other acts of god causing emergency situation; laws, regulation and/or government instructions requiring such limitation in communication; or otherwise TsunoMarket at its discretion deciding that such discontinuation is necessary, or
                    </li>
                    <li>
                      failure to provide Services due to technical reasons.
                    </li>
                  </ul>
                </li>
                <li>TsunoMarket disclaims any liability whatsoever for damage incurred to Clients caused by any such delay or temporary discontinuation.</li>
              </ul>
            </p>
            <p className="py-2">
              These Terms of Service are effective as of September 3, 2024
            </p>
            <h2 className="text-lg font-bold">Personal Information Protection Policy</h2>
            <p className="py-2">
              Unfolded Co.,ltd (Tsuno Market) recognizes that Clients&#39; Personal Information is important information that constitutes privacy in the course of conducting its purchasing agency, cross-border EC, shopping platform provision service, and related of our services business. When handling Personal Information in the course of business, Unfolded Co.,ltd(Tsuno Market) will respect our Clients and live up to their expectations and trust in us by establishing laws and regulations concerning Personal Information and internal rules stipulated for the protection of Personal Information, as well as by developing an organizational structure and striving to protect Personal Information appropriately.
            </p>
            <h2 className="text-lg font-bold">Acquisition, Use, and Provision of Personal Information</h2>
            <p className="py-2">
              We will specify the purposes of use of Personal Information within the scope of our business activities, and will acquire, use, and provide Personal Information fairly and appropriately to the extent necessary to achieve those purposes. We will also take steps to ensure that Personal Information acquired will not be used for purposes other than those specified.
            </p>
            <h2 className="text-lg font-bold">Proper Management of Personal Information</h2>
            <p className="py-2">
              We are fully aware of the dangers of unauthorized access, loss, destruction, falsification, and leakage of the Personal Information we handle, and we will implement reasonable security measures and take appropriate corrective action if problems arise.
            </p>
            <h2 className="text-lg font-bold">Compliance with Laws and Regulations</h2>
            <p className="py-2">
              We will comply with laws, national guidelines, other norms, and social order concerning Personal Information, and strive to protect Personal Information appropriately.
            </p>
            <h2 className="text-lg font-bold">Responding to Inquiries</h2>
            <p className="py-2">
              We will respond appropriately to inquiries from the Client regarding disclosure, correction, suspension of use, or complaints about Personal Information handled by us.
            </p>
            <h2 className="text-lg font-bold">Continuous Improvement</h2>
            <p className="py-2">
              We have established management regulations and a management system for the protection of Personal Information, which are thoroughly implemented by all employees, and are regularly reviewed in an effort to continuously improve our Personal Information protection management system.
            </p>
            <h2 className="text-lg font-bold">Payment Method</h2>
            <p className="py-2">
              Paypal
            </p>
            <h2 className="text-lg font-bold">Shipping Method</h2>
            <p className="py-2">
              LCL SERVICE TO Mongolia (Ulaanbaatar):LCL (Less than Container Load to Ulaanbaatar)
              └Averages 1.5month to 3months
            </p>
          </Box>
        </Container>
      </section>
    </CommonLayout>
  )
}
