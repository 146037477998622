import { Outlet } from "react-router-dom"
import { connect } from "react-redux"
import { Navigate } from "react-router-dom";

const AuthenticationComponent = (props) => {
  const {
    isSignedIn
  } = props;

  if (isSignedIn) {
    return <Outlet />
  } else {
    return <Navigate to="/signin" replace />
  }
}

export const Authentication = connect(state => ({...state.user}))(AuthenticationComponent)
