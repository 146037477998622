import React from "react";
import PropTypes from "prop-types";
import { useGetCategoryForFiltersQuery } from "../stores/services/categoryForFilter";
import { connect } from "react-redux";

export const SelectCategory = (props) => {
  const {
    categoryGroupId,
    value = "",
    onChange = () => {}
  } = props;

  const {
    data: categories = [],
    error: categoriesError,
    isLoading: categoriesLoading,
  } = useGetCategoryForFiltersQuery();

  const [ categoryMaster, setCategoryMaster ] = React.useState([]);
  const [ valueA, setValueA ] = React.useState();
  const [ valueB, setValueB ] = React.useState();
  const [ valueC, setValueC ] = React.useState();
  const [ valueD, setValueD ] = React.useState();
  const [ valueE, setValueE ] = React.useState();
  const [ valueF, setValueF ] = React.useState();

  React.useEffect(() => {
    setCategoryMaster(
      categories.map(c => (
        c.Category
      ))
      .filter(c => c.category_group_id === categoryGroupId)
    )
  }, [
    categories,
    categoryGroupId
  ])

  const onChangeHandler = (setFunc, newValue) => {
    setFunc(newValue);
    // onChange(categoryMaster.find(c => c.id === newValue)?.category_original_id)
  }

  const optionsForFirst = React.useMemo(() => {
    const ids = categoryMaster.map(c => c.id);
    return categoryMaster.filter(
      c => ids.includes(c.parent_category_id) === false
    )
  }, [
    categoryMaster
  ])

  React.useEffect(() => {
    onChange(
      categoryMaster.find(
        c => c.id === (valueF || valueE || valueD || valueC || valueB || valueA || '')
      )?.category_original_id || ''
    )
  }, [
    valueA,
    valueB,
    valueC,
    valueD,
    valueE,
    valueF,
  ])

  React.useEffect(() => {
    if (value === '') {
      setValueA('');
      setValueB('');
      setValueC('');
      setValueD('');
      setValueE('');
      setValueF('');
    }
  }, [
    value
  ])

  return (
    <div className="grid lg:grid-cols-3 grid-cols-1 gap-2">
      <SelectCategoryItem
        onChange={onChangeHandler.bind(null, setValueA)}
        value={valueA}
        options={optionsForFirst}
      />
      <SelectCategoryItem
        onChange={onChangeHandler.bind(null, setValueB)}
        value={valueB}
        options={categoryMaster.filter(c => c.parent_category_id === valueA)}
      />
      <SelectCategoryItem
        onChange={onChangeHandler.bind(null, setValueC)}
        value={valueC}
        options={categoryMaster.filter(c => c.parent_category_id === valueB)}
      />
      <SelectCategoryItem
        onChange={onChangeHandler.bind(null, setValueD)}
        value={valueD}
        options={categoryMaster.filter(c => c.parent_category_id === valueC)}
      />
      <SelectCategoryItem
        onChange={onChangeHandler.bind(null, setValueE)}
        value={valueE}
        options={categoryMaster.filter(c => c.parent_category_id === valueD)}
      />
      <SelectCategoryItem
        onChange={onChangeHandler.bind(null, setValueF)}
        value={valueF}
        options={categoryMaster.filter(c => c.parent_category_id === valueE)}
      />
    </div>
  )
}
SelectCategory.propTypes = {
  categoryGroupId: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
}
const SelectCategoryItemComponent = (props) => {
  const {
    lang,
    options = [],
    value = "",
    onChange = () => {}
  } = props;

  React.useEffect(() => {
    if (value === '') return;
    if (!options.find(op => op.id === value)) {
      onChange('');
    }
  }, [options])

  return (
    <select
      className="select select-bordered w-full focus:outline-none"
      onChange={e => onChange(Number(e.target.value))}
      value={value}
    >
      <option value="" selected>--</option>
      {
        options
          .map(c => (
            <option key={c.id} value={c.id}>{c[`name_${lang}`]}</option>
          ))
      }
    </select>
  )
}
export const SelectCategoryItem = connect(state => ({...state.i18n}))(SelectCategoryItemComponent)
SelectCategoryItemComponent.propTypes = {
  lang: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.number,
  onChange: PropTypes.func,
}

// vim:sw=2:ai
