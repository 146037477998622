import React from "react"
import axios from "../../lib/axios"
import { Box } from "../../components/layouts/Box"
import { useDispatch } from "react-redux"
import { addInfoToast, addErrorToast } from "../../stores/features/toast/toastSlice"
import { useUpdateUserMutation } from "../../stores/services/user";
import { DashboardLayout } from "../../components/layouts/DashboardLayout"
import { InputUserName } from "../../components/InputUserName";
import { InputName } from "../../components/InputName";

export const AccountPage = () => {
  const dispatch = useDispatch();
  const [ user, setUser ] = React.useState({});

  const [ updateUser ] = useUpdateUserMutation();

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await updateUser({
        id: user.id,
        given_name: user.given_name,
        family_name: user.family_name
      })
      if (res.error === undefined) {
        dispatch(addInfoToast('Successful changing your account'))
      } else {
        res.error.data.errorMessages.forEach(
          err => dispatch(addErrorToast(err))
        )
      }
    } catch(error) {
      error.response.data.errorMessages.forEach(
        err => dispatch(addErrorToast(err))
      )
    }
  }

  React.useEffect(() => {
    axios.get('/api/v1/me')
      .then(({data}) => setUser(data))
  }, [])

  return (
    <DashboardLayout>
      <div className="col-span-3">
        <Box className="lg:p-12 p-4">
          <div className="w-full max-w-sm mx-auto">
            <form onSubmit={onSubmit}>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">Email Address</span>
                </div>
                <InputUserName
                  value={user.email}
                  disabled
                />
                <div className="label">
                  <span className="label-text">You cannot change your email address</span>
                </div>
              </label>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">Given Name</span>
                </div>
                <InputName
                  value={user.given_name}
                  onChange={e => setUser(prev => ({...prev, given_name: e.target.value}))}
                  autoComplete="given-name"
                />
              </label>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">Last Name</span>
                </div>
                <InputName
                  value={user.family_name}
                  onChange={e => setUser(prev => ({...prev, family_name: e.target.value}))}
                  autoComplete="family-name"
                />
              </label>
              <button className="btn btn-primary w-full my-4" type="submit">
                <span className="material-symbols-outlined">
                  save
                </span>
                Save
              </button>
          </form>
        </div>
      </Box>
    </div>
  </DashboardLayout>
  )
}
