import React from 'react';
import PropTypes from 'prop-types'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


export const SwiperComponent = (props) => {
  const {
    images = [],
    ...rest
  } = props;

  const _swiper = React.useRef(null);

  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      onSwiper={(swiper) => _swiper.current = swiper}
      style={{'--swiper-navigation-color': '#424242'}}
      {...rest}
    >
      {
        images.map((image, i) => (
          <SwiperSlide key={i}>
            <img src={image} className="mx-auto" />
          </SwiperSlide>
        ))
      }
    </Swiper>
  )
}
SwiperComponent.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string)
}
