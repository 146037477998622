import React from "react"
import { NavLink } from "react-router-dom"
import { Box } from "../../components/layouts/Box"
import { DashboardLayout } from "../../components/layouts/DashboardLayout"
import moment from "moment"
import { useGetOrdersQuery, useUpdateOrderMutation } from "../../stores/services/order";
import { Image } from "../../components/Image";
import { FileLink } from "../../components/FileLink";
import {
  useGetShippingAddresssQuery,
  useCreateShippingAddressMutation,
  useUpdateShippingAddressMutation
} from "../../stores/services/shippingAddress";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { addErrorToast } from "../../stores/features/toast/toastSlice";

const getNumberFormatted = (num, currency, locale) => {
  return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(
    num,
  )
}
export const RequestHisotryPage = () => {
  const [ open, setOpen ] = React.useState();
  const {
    data = [],
    error,
    isLoading
  } = useGetOrdersQuery({
    scope: 'list'
  });

  const [ updateOrder ] = useUpdateOrderMutation();
  const { t } = useTranslation();

  const onClickAccordion = (i, e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(prev => prev !== i ? i : undefined);
  }

  const onCancelHandler = order => {
    updateOrder({
      id: order.id,
      status: 'CANCELED',
      cancel_reason: 'BY_USER'
    })
  }

  return (
    <DashboardLayout pageTitle={t('page.request-history')}>
      <div className="col-span-3">
        <Box>
          {
            data.length === 0 &&
              <div className="text-center p-4">
                <p>{t('order.no-data')}</p>
                <p>
                  <NavLink to="/dashboard/requestForm" className="link link-primary">{t('order.click-here-to')}</NavLink>
                </p>
              </div>
          }
          <div className="join join-vertical w-full">
            {
              data
                .map(
                  (r, i) =>
                    <RequestHisotryAccordion
                      key={i}
                      data={r}
                      open={i === open}
                      onOpen={onClickAccordion.bind(null, i)}
                      onCancel={onCancelHandler.bind(null, r)}
                    />
                )
            }
          </div>
        </Box>
      </div>
    </DashboardLayout>
  )
}

const mappingStatusToText = {
  'ORDERED': 'orderStatus.ordered',
  'CREATING_INVOICE_FOR_PRODUCT': 'orderStatus.creatingInvoiceForProduct',
  'WAITING_FOR_PAYMENT_FOR_PRODUCT': 'orderStatus.waitingForPaymentForProduct',
  'PROCCESSING_TO_BUY': 'orderStatus.proccessingToBuy',
  'DONE_TO_BUY': 'orderStatus.doneToBuy',
  'ARRIVED_AT_WAREHOUSE': 'orderStatus.arrivedAtWarehouse',
  'CREATING_INVOICE_FOR_SHIPPING_FEE': 'orderStatus.creatingInvoiceForShippingFee',
  'WAITING_FOR_PAYMENT_FOR_SHIPPING_FEE': 'orderStatus.waitingForPaymentForShippingFee',
  'PREPARING_TO_SHIP': 'orderStatus.preparingToShip',
  'SHIPPED': 'orderStatus.shipped',
  'IN_TRASIT': 'orderStatus.inTrasit',
  'ARRIVED': 'orderStatus.arrived',
  'CANCELED': 'orderStatus.canceled'
}
const mappingCancelReasonToText = {
  'BY_USER': 'cancelReason.byUser',
  'CAUSE_NOT_SUPPORTED': 'cancelReason.causeNotSupported',
  'CAUSE_DID_NOT_PAY': 'cancelReason.causeDidNotPay',
  'CAUSE_ALREADY_BOUGHT': 'cancelReason.causeALreadyBought',
  'CAUSE_COULD_NOT_PROCEED': 'cancelReason.causeCouldNotProceed',
  'CAUSE_COULD_NOT_SHIP': 'cancelReason.causeCouldNotShip',
  'CAUSE_SOMETHING': 'cancelReason.causeSomething',
}
const RequestHisotryAccordion = (props) => {
  const {
    open = false,
    onOpen = () => {},
    onCancel = () => {},
    data = {}
  } = props;
  const { t } = useTranslation();

  const [ openShippingAddressAccordion, setOpenShippingAddressAccordion ] = React.useState(false);

  const onClickAccordion = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenShippingAddressAccordion(!openShippingAddressAccordion);
  }

  const onClickCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (confirm(t('order.cancelConfirm'))) {
      onCancel()
    }
  }

  let productPageURL;
  switch (data.OrderItemRequested.auction_site) {
    case "YAHOO":
      productPageURL = 'https://page.auctions.yahoo.co.jp/jp/auction/';
      break;
    case "MERCARI":
      productPageURL = 'https://jp.mercari.com/item/';
      break;
  }
  productPageURL += data.OrderItemRequested.auction_id;

  return (
    <label
      className={"collapse collapse-arrow join-item border-base-300 border " + (open ? 'collapse-open': '')}
      onClick={onOpen}
    >
      <div className="collapse-title text-lg font-medium">
          {t('order.request')} #{data.id.toString().padStart(5, '0')}
        <div className="w-full">
          <div className={"ml-2 badge badge-outline " + (data.status === 'CANCELED' ? "badge-error": "badge-primary")}>{t(mappingStatusToText[data.status] + '.badge')}</div>
          {
            (['WAITING_FOR_PAYMENT_FOR_PRODUCT', 'ARRIVED_AT_WAREHOUSE', 'WAITING_FOR_PAYMENT_FOR_SHIPPING_FEE'].includes(data.status)) &&
              <div className="ml-2 badge badge-error badge-outline">{t('order.needAction')}</div>
          }
        </div>
      </div>
      <div className="collapse-content" onClick={e => e.stopPropagation()}>
        {
          data.OrderItemRequested.auction_page_image_id &&
          <div className="p-4 h-64">
            <Image srcId={data.OrderItemRequested.auction_page_image_id} className="h-full mx-auto" />
          </div>
        }
        <div className="font-bold">
          {data.OrderItemRequested.product_name}
        </div>
        <div className="text-sm">
          {getNumberFormatted(data.OrderItemRequested.product_price, 'jpy', 'ja-JP')}
        </div>
        <div className="mt-2 flex items-center text-primary">
          <a href={productPageURL} className="link link-primary text-md" target="_blank">
            {t('order.product-page')}
          </a>
          <span className="material-symbols-outlined text-md">
            open_in_new
          </span>
        </div>
        <div className="divider"></div>
        <div className="flex flex-col gap-2">
          <div>
            <label className="text-sm font-bold">
              {t('order.requestDate')}
            </label>
            <div>
              {moment(data.ordered_at).format('LLL')}
            </div>
          </div>
          {
            data.status === 'SHIPPED' &&
            <div>
              <label className="text-sm font-bold">
                {t('order.shipDate')}
              </label>
              <div>
                {moment(data.shipped_at).format('LLL')}
              </div>
            </div>
          }
          {
            data.status === 'CANCELED' &&
            <div>
              <label className="text-sm font-bold">
                {t('order.cancelDate')}
              </label>
              <div>
                {moment(data.canceled_at).format('LLL')}
              </div>
            </div>
          }
          <div>
            <label className="text-sm font-bold">
                {t('order.status')}
            </label>
            <div>
              {t(mappingStatusToText[data.status])}
            </div>
          </div>
          {
            data.status === 'CANCELED' &&
            <div className="text-error font-bold">
              <div>
                {t(mappingCancelReasonToText[data.cancel_reason])}
              </div>
              <div>
                {data.cancel_reason_additional}
              </div>
            </div>
          }
          {
            data.status === 'ORDERED' &&
            <a className="link link-error" onClick={onClickCancel}>{t('order.cancelRequest')}</a>
          }
          {
            ['ORDERED', 'CREATING_INVOICE_FOR_PRODUCT', 'CANCELED'].includes(data.status) === false &&
            <div>
              <label className="text-sm font-bold">
                {t('order.amountForProduct')}
              </label>
              <div>
                {getNumberFormatted(data.amount_for_product, 'jpy', 'ja-JP')}
              </div>
            </div>
          }
          {
            ['WAITING_FOR_PAYMENT_FOR_SHIPPING_FEE', 'PREPARING_TO_SHIP', 'SHIPPED', 'IN_TRASIT', 'ARRIVED'].includes(data.status) &&
            <div>
              <label className="text-sm font-bold">
                {t('order.amountForShippingFee')}
              </label>
              <div>
                {getNumberFormatted(data.amount_for_shipping_fee, 'jpy', 'ja-JP')}
              </div>
            </div>
          }
          {
            data.status === 'WAITING_FOR_PAYMENT_FOR_PRODUCT' &&
              <div>
                <a href={data.payment_link_for_product} className="link link-primary" target="_blank">
                  {t('order.goToPaymentPage')}
                </a>
                <div className="text-sm">* {t('order.goToPaymentPage.please')}</div>
              </div>
          }
          {
            data.status === 'WAITING_FOR_PAYMENT_FOR_SHIPPING_FEE' &&
              <div>
                <a href={data.payment_link_for_shipping_fee} className="link link-primary" target="_blank">
                  {t('order.goToPaymentPage')}
                </a>
                <div className="text-sm">* {t('order.goToPaymentPage.please')}</div>
              </div>
          }
          {
            ['SHIPPED', 'IN_TRASIT', 'ARRIVED'].includes(data.status) &&
              <div>
                <label className="text-sm font-bold">
                  {t('order.trackingNumber')}
                </label>
                <div>
                  {data.tracking_number}
                </div>
              </div>
          }
          {
            ['ARRIVED_AT_WAREHOUSE', 'CREATING_INVOICE_FOR_SHIPPING_FEE', 'WAITING_FOR_PAYMENT_FOR_SHIPPING_FEE', 'PREPARING_TO_SHIP', 'SHIPPED', 'IN_TRASIT', 'ARRIVED'].includes(data.status) &&
              <div className={"collapse collapse-plus " + (openShippingAddressAccordion ? 'collapse-open' : '')} onClick={e => e.stopPropagation()}>
                <div className="collapse-title px-0 text-primary" onClick={onClickAccordion}>{t('order.shippingInstruction')}</div>
                <div className="collapse-content px-0">
                  <ShippingAddress orderId={data.id} readOnly={data.status !== 'ARRIVED_AT_WAREHOUSE'} />
                </div>
                {
                  data.status === 'ARRIVED_AT_WAREHOUSE' &&
                    <div className="text-sm">* {t('order.shippingInstruction.please')}</div>
                }
              </div>
          }
          {
            ['SHIPPED', 'IN_TRASIT', 'ARRIVED'].includes(data.status) &&
              <div>
                <FileLink srcId={data.bill_of_lading_file_id} className="link link-primary">
                  {t('order.billOfLadingFileDownload')}
                </FileLink>
              </div>
          }
        </div>
      </div>
    </label>
  )
}
const ShippingAddress = (props) => {
  const {
    orderId,
    readOnly = false
  } = props;

  const [ _data, _setData ] = React.useState({
    order_id: orderId,
    address_line1: null,
    address_line2: null,
    address_line3: null,
    address1: null,
    address2: null,
    city: null,
    company_name: null,
    country: null,
    name: null,
    postalCode: null,
    shipping_method: null,
    state: null,
    tel: null,
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [ createShippingAddress ] = useCreateShippingAddressMutation();
  const [ updateShippingAddress ] = useUpdateShippingAddressMutation();

  const {
    data: fetchedData = [],
    error,
    isLoading
  } = useGetShippingAddresssQuery({
    order_id: orderId
  });

  const onSubmit = (e) => {
    e.preventDefault();

    if (_data.id) {
      updateShippingAddress(_data)
        .then(({error = null}) => {
          if (error === null) return;

          error.data.errorMessages.forEach(
            err => dispatch(addErrorToast(err))
          )
        })
    } else {
      createShippingAddress(_data)
        .then(({error = null}) => {
          if (error === null) return;
          error.data.errorMessages.forEach(
            err => dispatch(addErrorToast(err))
          )
        })
    }
    return;
  }

  React.useEffect(() => {
    _setData(prev => ({...prev, ...fetchedData[0]}))
  }, [fetchedData])

  const setValue = (k, e) => {
    _setData(prev => ({
      ...prev,
      [k]: e.target.value
    }))
  }

  return (
    <form onSubmit={onSubmit}>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">{t('order.shippingMethod')}</span>
        </div>
        <select className="select select-bordered w-full focus:outline-none" value={_data.shipping_method} onChange={setValue.bind(null, 'shipping_method')} disabled={readOnly}>
          <option disabled selected>{t('order.shippingMethod.placeholder')}</option>
          <option value="LCL">{t('order.shippingMethod.lcl')}</option>
          <option value="SAGAWA">{t('order.shippingMethod.sagawa')}</option>
        </select>
      </label>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">{t('order.shippingName')}</span>
        </div>
        <input type="text" autoComplete="shipping name" className="input input-bordered w-full focus:outline-none" value={_data.name} onChange={setValue.bind(null, 'name')} readOnly={readOnly} />
      </label>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">{t('order.shippingCompanyName')}</span>
        </div>
        <input type="text" autoComplete="shipping organization" className="input input-bordered w-full focus:outline-none" value={_data.company_name} onChange={setValue.bind(null, 'company_name')} readOnly={readOnly} />
      </label>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">{t('order.shippingCountry')}</span>
        </div>
        <select autoComplete="shipping country-name" className="select select-bordered w-full focus:outline-none" value={_data.country} onChange={setValue.bind(null, 'country')} disabled={readOnly}>
          <option disabled selected>{t('order.shippingCountry.placeholder')}</option>
          <option value="Mongolia">{t('country.mongolia')}</option>
        </select>
      </label>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">{t('order.shippingAddressLine1')}</span>
        </div>
        <input type="text" autoComplete="shipping address-line1" className="input input-bordered w-full focus:outline-none" value={_data.address_line1} onChange={setValue.bind(null, 'address_line1')} readOnly={readOnly} />
      </label>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">{t('order.shippingAddressLine2')}</span>
        </div>
        <input type="text" autoComplete="shipping address-line2" className="input input-bordered w-full focus:outline-none" value={_data.address_line2} onChange={setValue.bind(null, 'address_line2')} readOnly={readOnly} />
      </label>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">{t('order.shippingAddressLine3')}</span>
        </div>
        <input type="text" autoComplete="shipping address-line3" className="input input-bordered w-full focus:outline-none" value={_data.address_line3} onChange={setValue.bind(null, 'address_line3')} readOnly={readOnly} />
      </label>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">{t('order.shippingAddress1')}</span>
        </div>
        <input type="text" autoComplete="shipping address-level4" className="input input-bordered w-full focus:outline-none" value={_data.address1} onChange={setValue.bind(null, 'address1')} readOnly={readOnly} />
      </label>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">{t('order.shippingAddress2')}</span>
        </div>
        <input type="text" autoComplete="shipping address-level3" className="input input-bordered w-full focus:outline-none" value={_data.address2} onChange={setValue.bind(null, 'address2')} readOnly={readOnly} />
      </label>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">{t('order.shippingAddressCity')}</span>
        </div>
        <input type="text" autoComplete="shipping address-level2" className="input input-bordered w-full focus:outline-none" value={_data.city} onChange={setValue.bind(null, 'city')} readOnly={readOnly} />
      </label>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">{t('order.shippingAddressState')}</span>
        </div>
        <input type="text" autoComplete="shipping address-level1" className="input input-bordered w-full focus:outline-none" value={_data.state} onChange={setValue.bind(null, 'state')} readOnly={readOnly} />
      </label>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">{t('order.shippingAddressPostalCode')}</span>
        </div>
        <input type="text" autoComplete="shipping postal-code" className="input input-bordered w-full focus:outline-none" value={_data.postalCode} onChange={setValue.bind(null, 'postalCode')} readOnly={readOnly} />
      </label>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">{t('order.shippingAddressTel')}</span>
        </div>
        <input type="tel" autoComplete="shipping tel" className="input input-bordered w-full focus:outline-none" value={_data.tel} onChange={setValue.bind(null, 'tel')} readOnly={readOnly} />
      </label>
      <button type="submit" className="btn btn-primary w-full mt-4" disabled={readOnly}>
        {t('order.shippingAddressDone')}
      </button>
    </form>
  )
}
