import React from "react"
import { CommonLayout } from "../../components/layouts/CommonLayout"
import { Container } from "../../components/layouts/Container"
import { ChooseListDrawer } from "../../components/ChooseListDrawer";
import { CategoryGroupChooseListDrawer } from "../../components/CategoryGroupChooseListDrawer";
import { useTranslation } from 'react-i18next';

const targetSiteOption = [
  {
    categoryGroupId: 1,
    name: 'Yahoo Auction',
    query: 'p',
    endpoint: 'https://auctions.yahoo.co.jp/search/search',
    logoUrl: 'https://s.yimg.jp/c/logo/f/2.1/a/auctions_r_34_2x.png',
    others: [
      { key: 'fixed', value: () => '1' },
      { key: 'is_postage_mode', value: () => '1' },
      { key: 'dest_pref_code', value: () => '13' },
      { key: 'exflg', value: () => '1' },
      { key: 'b', value: () => '1' },
      { key: 'n', value: () => '50' },
      { key: 'auccat', value: c => c || "" },
    ]
  },
  {
    categoryGroupId: 2,
    name: 'Mercari',
    query: 'keyword',
    endpoint: 'https://jp.mercari.com/search',
    logoUrl: 'https://about.mercari.com/sample/index/serv-mercari.svg',
    others: [
      { key: 'category_id', value: c => c || "" },
    ]
  }
]
export const TireSearchPage = () => {
  const [ values, setValues ] = React.useState({});
  const [ targetSite, setTargetSite ] = React.useState({});
  const { t } = useTranslation();

  React.useEffect(() => {
    setTargetSite(
      targetSiteOption.find(
        v => v.categoryGroupId === values.website?.id
      ) || {}
    )
  }, [ values ])

  const onSubmit = (e) => {
    if (!confirm(t('warning.before-open'))) {
      e.preventDefault();
      return false;
    }
  }

  return (
    <CommonLayout>
      <Container className="max-w-[640px]">
        <div className="p-2 h-[80vh]">
          <form action={targetSite.endpoint} method="get" target="_blank" onSubmit={onSubmit}>
            <CategoryGroupChooseListDrawer
              value={values.website}
              onSelect={option => setValues(prev => ({...prev, website: option, category: null}))}
            />
            <ChooseListDrawer
              label={t('chooseListDrawer.choose-width')}
              value={values.width}
              options={[
135 ,145 ,155 ,165 ,175 ,185 ,195 ,205 ,215 ,225 ,235 ,245 ,255 ,265 ,275 ,285 ,295 ,305 ,315 ,325 ,335 ,345 ,355 ,365 ,375 ,385 ,395 ,405
              ].map(r => ({name: r + " mm", value: r}))}
              onSelect={option => setValues(prev => ({...prev, width: option}))}
            />
            <ChooseListDrawer
              label={t('chooseListDrawer.choose-ratio')}
              value={values.ratio}
              options={[
                35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85
              ].map(r => ({ name: r + " %", value: r }))}
              onSelect={option => setValues(prev => ({...prev, ratio: option}))}
            />
            <ChooseListDrawer
              label={t('chooseListDrawer.choose-diameter')}
              value={values.diameter}
              options={[
                10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21
              ].map(r => ({ name: r + " inch", value: r }))}
              onSelect={option => setValues(prev => ({...prev, diameter: option}))}
            />
            <div className="mt-2">
              <button
                type="submit"
                className="btn btn-primary w-full my-2"
                disabled={!values.website || !values.width || !values.ratio || !values.diameter}
              >
                {t('common.search')}
              </button>
              <button
                type="button"
                className="btn btn-error btn-outline w-full my-2"
                onClick={() => setValues({})}
              >
                {t('common.reset')}
              </button>
            </div>
            <input
              type="hidden"
              name={targetSite.query}
              value={[`${values.width?.value}/${values.ratio?.value}R${values.diameter?.value}`].filter(v => v !== '').join('　')}
              readOnly
            />
            {
              targetSite.others?.map((other, i) => (
                <input key={i} type="hidden" name={other.key} value={other.value()} />
              ))
            }
            <ul>
              <li>* {t('note.before-request')}</li>
            </ul>
          </form>
        </div>
      </Container>
    </CommonLayout>
  )
}
// vim:sw=2:ai
