import { Link } from "react-router-dom";
import { CommonLayout } from "./CommonLayout";
import { Box } from "./Box";
import { SidemenuItem } from "../SidemenuItem";
import { SidemenuTitle } from "../SidemenuTitle";
import { Container } from "./Container";
import { PageTitle } from "../styled/PageTitle";

export const DashboardLayout = (props) => {
  const {
    pageTitle = "My Account",
    children
  } = props;
  return (
    <CommonLayout>
      <section className="bg-base-200">
        <Container>
          <PageTitle>
            {pageTitle}
          </PageTitle>
          <div className="grid lg:grid-cols-3 grid-cols-3 gap-6">
            <div className="row-span-2 flex flex-col gap-2 hidden lg:hidden">
              <Box className="w-full p-6">
                <SidemenuTitle>Accout Settings</SidemenuTitle>
                <SidemenuItem>
                  <Link to="/dashboard">
                    <span className="group-hover:text-primary">My Account Top</span>
                  </Link>
                </SidemenuItem>
                <SidemenuItem>
                  <Link to="/dashboard/account">
                    <span className="group-hover:text-primary">Change Account Details</span>
                  </Link>
                </SidemenuItem>
                <SidemenuItem>
                  <Link to="/dashboard/changePassword">
                    <span className="group-hover:text-primary">Change Password</span>
                  </Link>
                </SidemenuItem>
              </Box>
            </div>
            {children}
          </div>
        </Container>
      </section>
    </CommonLayout>
  )
}
