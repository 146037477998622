import React from "react"
import axios from "axios";
import { useDispatch } from "react-redux";
import { CommonLayout } from "../components/layouts/CommonLayout"
import { Container } from "../components/layouts/Container"
import { Box } from "../components/layouts/Box"
import { signOut } from "../stores/features/user/userSlice";
import { useTranslation } from 'react-i18next';
import { PageTitle } from "../components/styled/PageTitle";

export const SignoutPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    axios.get('/api/v1/logout', {
      headers: {
        Authorization: window.localStorage.getItem('sessionId')
      }
    }).finally(() => {
      dispatch(signOut());
    })
  }, [])

  return (
    <CommonLayout>
      <section className="bg-base-200">
        <Container>
          <PageTitle>
            {t('common.signOut')}
          </PageTitle>
          <Box className="lg:p-12 p-4">
            <div className="w-full max-w-sm mx-auto">
              <div className="font-bold text-lg text-center p-4">{t('signOut.you-have-been-signed-out')}</div>
            </div>
          </Box>
        </Container>
      </section>
    </CommonLayout>
  )
}
