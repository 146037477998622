import PropTypes from 'prop-types';
import { ChooseListDrawer } from "./ChooseListDrawer";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

const BrandChooseListDrawerComponent = (props) => {

  const {
    lang,
    value,
    onSelect = () => {},
    ...rest
  } = props;
  const { t } = useTranslation();

  const options = [
    {
      id: 1,
      name_ja: 'JAOS',
      name_en: 'JAOS',
    },
    {
      id: 2,
      name_ja: 'TRD',
      name_en: 'TRD',
    },
    {
      id: 3,
      name_ja: 'MODELLISTA',
      name_en: 'MODELLISTA',
    },
    {
      id: 4,
      name_ja: 'TEIN',
      name_en: 'TEIN',
    },
  ]

  return (
    <ChooseListDrawer
      label={t('chooseListDrawer.choose-brand')}
      value={value}
      options={options}
      onSelect={onSelect}
      labelProp={`name_${lang}`}
      valueProp="id"
      {...rest}
    />
  )
}
BrandChooseListDrawerComponent.propTypes = {
  lang: PropTypes.string,
  value: PropTypes.any,
  onSelect: PropTypes.func
}
export const BrandChooseListDrawer = connect((state) => ({ ...state.i18n }))(BrandChooseListDrawerComponent);
