import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../fetchBaseQuery';

export const recommendedCategoryGroupApi = createApi({
    reducerPath: 'recommendedCategoryGroupApi',
    baseQuery,
    tagTypes: [
        'RecommendedCategoryGroup'
    ],
    endpoints: (builder) => ({
        getRecommendedCategoryGroups: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/recommendedCategoryGroups';
                } else {
                    return '/recommendedCategoryGroups?' + new URLSearchParams(queries);
                }
            },
            providesTags: (result, error, arg) =>
            result
            ? [...result.map(({ id }) => ({ type: 'RecommendedCategoryGroup', id })), 'RecommendedCategoryGroup']
            : ['RecommendedCategoryGroup'],
        }),
        countRecommendedCategoryGroups: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/recommendedCategoryGroups/counts';
                } else {
                    return '/recommendedCategoryGroups/counts?' + new URLSearchParams(queries);
                }
            }
        }),
        getRecommendedCategoryGroup: builder.query({
            query: (id) => `/recommendedCategoryGroup/${id}`,
            providesTags: (result, error, arg) => [{type: 'RecommendedCategoryGroup', id: result.id}]
        }),
        createRecommendedCategoryGroup: builder.mutation({
            query: (body) => ({
                url: `/recommendedCategoryGroup`,
                method: 'POST',
                body,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: ['RecommendedCategoryGroup']
        }),
        updateRecommendedCategoryGroup: builder.mutation({
            query: ({ id, ...patch }) => ({
                url: `/recommendedCategoryGroup/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'RecommendedCategoryGroup', id: arg.id}]
        }),
        deleteRecommendedCategoryGroup: builder.mutation({
            query: (id) => ({
                url: `/recommendedCategoryGroup/${id}`,
                method: 'DELETE'
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'RecommendedCategoryGroup', id: arg.id}]
        }),
    }),
})

export const {
    useGetRecommendedCategoryGroupsQuery,
    useCountRecommendedCategoryGroupsQuery,
    useGetRecommendedCategoryGroupQuery,
    useCreateRecommendedCategoryGroupMutation,
    useUpdateRecommendedCategoryGroupMutation,
    useDeleteRecommendedCategoryGroupMutation,
} = recommendedCategoryGroupApi
