import React from 'react';
import moment from 'moment-timezone';

export const Clock = () => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    ref.current.textContent = moment().tz('Asia/Tokyo').format('HH:mm:ss') + ' JST';
    const id = setInterval(() => {
      ref.current.textContent = moment().tz('Asia/Tokyo').format('HH:mm:ss') + ' JST';
    }, 1000);

    return () => {
      clearInterval(id);
    }
  }, [])
  return <div ref={ref}></div>
}
Clock.propTypes = {}
