import {
  createBrowserRouter,
  Outlet,
  ScrollRestoration
} from "react-router-dom";
import { Authentication } from "./components/Authentication";
import { IndexPage } from './pages';
import { DashBoardPage } from './pages/dashboard';
import { EstimatePage } from "./pages/estimate.jsx";
import { SigninPage } from "./pages/signin.jsx";
import { SignoutPage } from "./pages/signout.jsx";
import { SignupPage } from "./pages/signup.jsx";
import { ChangePasswordPage } from "./pages/dashboard/changePassword.jsx";
import { AccountPage } from "./pages/dashboard/account.jsx";
import { CategorySearchPage } from "./pages/search/category";
import { BrandSearchPage } from "./pages/search/brand";
import { TireSearchPage } from "./pages/search/tire.jsx";
import { RequestHisotryPage } from "./pages/dashboard/requestHistory";
import { RequestFormPage } from "./pages/dashboard/requestForm";
import { FirstTimeUserPage } from "./pages/firstTimeUser";
import { QAPage } from "./pages/qa";
import { TermsOfUsePage } from "./pages/termsOfUse";
import { PrivacyAndCookiePolicyPage } from "./pages/privacyAndCookiePolicy";
import { ServicePolicyPage } from "./pages/servicePolicy";
import { AboutUsPage } from "./pages/aboutUs";
import { ForgotPasswordPage } from "./pages/forgotPassword";
import { ResetPasswordPage } from "./pages/resetPassword";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <><Outlet /><ScrollRestoration /></>,
    children: [
      {
        path: "",
        element: <IndexPage />,
      },
      {
        path: "/firstTimeUser",
        element: <FirstTimeUserPage />,
      },
      {
        path: "/qa",
        element: <QAPage />,
      },
      {
        path: "/signin",
        element: <SigninPage />,
      },
      {
        path: "/signup",
        element: <SignupPage />,
      },
      {
        path: "/signout",
        element: <SignoutPage />,
      },
      {
        path: "/forgotPassword",
        element: <ForgotPasswordPage />,
      },
      {
        path: "/resetPassword/:token",
        element: <ResetPasswordPage />,
      },
      {
        path: "/dashboard",
        element: <Authentication />,
        children: [
          {
            path: '',
            element: <DashBoardPage />
          },
          {
            path: 'account',
            element: <AccountPage />
          },
          {
            path: 'changePassword',
            element: <ChangePasswordPage />
          },
          {
            path: 'requestHistory',
            element: <RequestHisotryPage />
          },
          {
            path: 'requestForm',
            element: <RequestFormPage />
          },
        ]
      },
      {
        path: "/estimate",
        element: <EstimatePage />,
      },
      {
        path: "/search",
        element: <Outlet />,
        children: [
          {
            path: 'category',
            element: <CategorySearchPage />
          },
          {
            path: 'brand',
            element: <BrandSearchPage />
          },
          {
            path: 'tire',
            element: <TireSearchPage />
          }
        ]
      },
      {
        path: "/terms-of-use",
        element: <TermsOfUsePage />,
      },
      {
        path: "/privacy-and-cookie-policy",
        element: <PrivacyAndCookiePolicyPage />,
      },
      {
        path: "/service-policy",
        element: <ServicePolicyPage />,
      },
      {
        path: "/about-us",
        element: <AboutUsPage />,
      },
    ]
  },
]);
