import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../fetchBaseQuery';

export const newsApi = createApi({
    reducerPath: 'newsApi',
    baseQuery,
    tagTypes: [
        'News'
    ],
    endpoints: (builder) => ({
        getNewss: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/newss';
                } else {
                    return '/newss?' + new URLSearchParams(queries);
                }
            },
            providesTags: (result, error, arg) =>
            result
            ? [...result.map(({ id }) => ({ type: 'News', id })), 'News']
            : ['News'],
        }),
        countNewss: builder.query({
            query: (queries = null) => {
                if (queries === null) {
                    return '/newss/counts';
                } else {
                    return '/newss/counts?' + new URLSearchParams(queries);
                }
            }
        }),
        getNews: builder.query({
            query: (id) => `/news/${id}`,
            providesTags: (result, error, arg) => [{type: 'News', id: result.id}]
        }),
        createNews: builder.mutation({
            query: (body) => ({
                url: `/news`,
                method: 'POST',
                body,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: ['News']
        }),
        updateNews: builder.mutation({
            query: ({ id, ...patch }) => ({
                url: `/news/${id}`,
                method: 'PATCH',
                body: patch,
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'News', id: arg.id}]
        }),
        deleteNews: builder.mutation({
            query: (id) => ({
                url: `/news/${id}`,
                method: 'DELETE'
            }),
            transformResponse: (response, meta, arg) => response,
            invalidatesTags: (result, error, arg) => [{type: 'News', id: arg.id}]
        }),
    }),
})

export const {
    useGetNewssQuery,
    useCountNewssQuery,
    useGetNewsQuery,
    useCreateNewsMutation,
    useUpdateNewsMutation,
    useDeleteNewsMutation,
} = newsApi
