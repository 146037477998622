import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const items = [
  {
    name: 'navigation.home',
    icon: 'home',
    href: '/'
  },
  {
    name: 'navigation.request',
    icon: 'assignment',
    href: '/dashboard/requestForm'
  },
  {
    name: 'navigation.shopping-cart',
    icon: 'shopping_cart',
    href: ''
  },
  {
    name: 'navigation.mypage',
    icon: 'person',
    href: '/dashboard'
  },
]
export const BottomNavigation = () => {
  const { t } = useTranslation();
  return (
    <div className="lg:hidden grid grid-cols-4 py-1 border-y-2 border-primary-content/20 bg-white sticky bottom-0">
      {
        items
          .map(
            (item, i) => (
              <NavLink
                key={i}
                to={item.href}
                className={({ isActive }) =>
                  [
                  isActive && item.href.length > 0 ? "" : "text-black/50",
                  ].join(" ")
                }
                end
              >
                <div className="flex flex-col justify-center">
                  <span className="material-symbols-outlined text-center">
                    {item.icon}
                  </span>
                  <div className="text-center">
                    {t(item.name)}
                  </div>
                </div>
              </NavLink>
            )
          )
      }
    </div>
  )
}
